//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../../common/user";
import { summaryReportService } from "../../../../services/admin/summaryReportV2.service";
import { setGeneralProgressHide, setGeneralProgressShow } from "../../../common/ui";

interface IObj {
  [i:string]: string;
}

export type GroupByObj = {
  [k: string]: string | IObj
}

type OptionsDataType = {
  group_by: GroupByObj,
  row_on_page: number[],
  separators_for_format: {
    csv: string[];
  },
  encoding_types: string[];
  available_filters: string[];
}

export type SummaryReportSliceState = {
  options: {
    data: OptionsDataType | null,
    error: any,
    isFetching: boolean,
  },
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  columns: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  download: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  products: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  managers: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  affiliates: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackers: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deals: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  creatives: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  brands: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  currencies: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  platforms: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deviceTypes: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: SummaryReportSliceState = {
  options: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  columns: {
    data: null,
    error: null,
    isFetching: false,
  },
  download: {
    data: null,
    error: null,
    isFetching: false,
  },
  //filters fields
  products: {
    data: null,
    error: null,
    isFetching: false,
  },
  managers: {
    data: null,
    error: null,
    isFetching: false,
  },
  affiliates: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackers: {
    data: null,
    error: null,
    isFetching: false,
  },
  deals: {
    data: null,
    error: null,
    isFetching: false,
  },
  creatives: {
    data: null,
    error: null,
    isFetching: false,
  },
  brands: {
    data: null,
    error: null,
    isFetching: false,
  },
  currencies: {
    data: null,
    error: null,
    isFetching: false,
  },
  platforms: {
    data: null,
    error: null,
    isFetching: false,
  },
  deviceTypes: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getOptionsData: any = createAsyncThunk(
  'adm_summary_report_v2/getOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'adm_summary_report_v2/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await summaryReportService.getReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getColumnsData: any = createAsyncThunk(
  'adm_summary_report_v2/getColumnsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await summaryReportService.getColumns();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getManagersData: any = createAsyncThunk(
  'adm_summary_report_v2/getManagersData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getManagers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAffiliatesData: any = createAsyncThunk(
  'adm_summary_report_v2/getAffiliatesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getAffiliates(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackersData: any = createAsyncThunk(
  'adm_summary_report_v2/getTrackersData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getProductsData: any = createAsyncThunk(
  'adm_summary_report_v2/getProductsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getProducts(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getBrandsData: any = createAsyncThunk(
  'adm_summary_report_v2/getBrandsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getBrands(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCreativesData: any = createAsyncThunk(
  'adm_summary_report_v2/getCreativesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getCreatives(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCurrenciesData: any = createAsyncThunk(
  'adm_summary_report_v2/getCurrenciesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getCurrencies(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPlatformsData: any = createAsyncThunk(
  'adm_summary_report_v2/getPlatformsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getPlatforms(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getDealsData: any = createAsyncThunk(
  'adm_summary_report_v2/getDealsData',
  async (_: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getDeals();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const setDownloadData: any = createAsyncThunk(
  'adm_summary_report_v2/setDownloadData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await summaryReportService.setDownload(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getDeviceTypesData: any = createAsyncThunk(
  'adm_summary_report_v2/getDeviceTypesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getDeviceTypes(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const summaryReportSlice = createSlice({
  name: 'adm_summary_report_v2',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearColumnsState(state) {
      state.columns.data = null;
      state.columns.error = null;
      state.columns.isFetching = false;
    },
    clearAffiliatesState(state) {
      state.affiliates.data = null;
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
    },
    clearTrackersState(state) {
      state.trackers.data = null;
      state.trackers.error = null;
      state.trackers.isFetching = false;
    },
    clearDownload(state) {
      state.download.data = null;
      state.download.error = null;
      state.download.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<SummaryReportSliceState>>) => {
    builder.addCase(getOptionsData.pending, (state ) => {
      state.options.isFetching = true;
      state.options.error = null;
    });
    builder.addCase(getOptionsData.fulfilled, (state , action) => {
      state.options.error = null;
      state.options.isFetching = false;
      state.options.data = action.payload;
    });
    builder.addCase(getOptionsData.rejected, (state , action) => {
      state.options.isFetching = false;
      state.options.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getColumnsData.pending, (state ) => {
      state.columns.isFetching = true;
      state.columns.error = null;
    });
    builder.addCase(getColumnsData.fulfilled, (state , action) => {
      state.columns.error = null;
      state.columns.isFetching = false;
      state.columns.data = action.payload;
    });
    builder.addCase(getColumnsData.rejected, (state , action) => {
      state.columns.isFetching = false;
      state.columns.error = action.payload;
    });

    builder.addCase(setDownloadData.pending, (state ) => {
      state.download.isFetching = true;
      state.download.error = null;
    });
    builder.addCase(setDownloadData.fulfilled, (state , action) => {
      state.download.error = null;
      state.download.isFetching = false;
      state.download.data = action.payload;
    });
    builder.addCase(setDownloadData.rejected, (state , action) => {
      state.download.isFetching = false;
      state.download.error = action.payload;
    });

    builder.addCase(getAffiliatesData.pending, (state ) => {
      state.affiliates.isFetching = true;
      state.affiliates.error = null;
    });
    builder.addCase(getAffiliatesData.fulfilled, (state , action) => {
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
      state.affiliates.data = action.payload;
    });
    builder.addCase(getAffiliatesData.rejected, (state , action) => {
      state.affiliates.isFetching = false;
      state.affiliates.error = action.payload;
    });

    builder.addCase(getTrackersData.pending, (state ) => {
      state.trackers.isFetching = true;
      state.trackers.error = null;
    });
    builder.addCase(getTrackersData.fulfilled, (state , action) => {
      state.trackers.error = null;
      state.trackers.isFetching = false;
      state.trackers.data = action.payload;
    });
    builder.addCase(getTrackersData.rejected, (state , action) => {
      state.trackers.isFetching = false;
      state.trackers.error = action.payload;
    });

    builder.addCase(getDealsData.pending, (state ) => {
      state.deals.isFetching = true;
      state.deals.error = null;
    });
    builder.addCase(getDealsData.fulfilled, (state , action) => {
      state.deals.error = null;
      state.deals.isFetching = false;
      state.deals.data = action.payload;
    });
    builder.addCase(getDealsData.rejected, (state , action) => {
      state.deals.isFetching = false;
      state.deals.error = action.payload;
    });

    builder.addCase(getCreativesData.pending, (state ) => {
      state.creatives.isFetching = true;
      state.creatives.error = null;
    });
    builder.addCase(getCreativesData.fulfilled, (state , action) => {
      state.creatives.error = null;
      state.creatives.isFetching = false;
      state.creatives.data = action.payload;
    });
    builder.addCase(getCreativesData.rejected, (state , action) => {
      state.creatives.isFetching = false;
      state.creatives.error = action.payload;
    });

    builder.addCase(getProductsData.pending, (state ) => {
      state.products.isFetching = true;
      state.products.error = null;
    });
    builder.addCase(getProductsData.fulfilled, (state , action) => {
      state.products.error = null;
      state.products.isFetching = false;
      state.products.data = action.payload;
    });
    builder.addCase(getProductsData.rejected, (state , action) => {
      state.products.isFetching = false;
      state.products.error = action.payload;
    });

    builder.addCase(getManagersData.pending, (state ) => {
      state.managers.isFetching = true;
      state.managers.error = null;
    });
    builder.addCase(getManagersData.fulfilled, (state , action) => {
      state.managers.error = null;
      state.managers.isFetching = false;
      state.managers.data = action.payload;
    });
    builder.addCase(getManagersData.rejected, (state , action) => {
      state.managers.isFetching = false;
      state.managers.error = action.payload;
    });

    builder.addCase(getBrandsData.pending, (state ) => {
      state.brands.isFetching = true;
      state.brands.error = null;
    });
    builder.addCase(getBrandsData.fulfilled, (state , action) => {
      state.brands.error = null;
      state.brands.isFetching = false;
      state.brands.data = action.payload;
    });
    builder.addCase(getBrandsData.rejected, (state , action) => {
      state.brands.isFetching = false;
      state.brands.error = action.payload;
    });

    builder.addCase(getCurrenciesData.pending, (state ) => {
      state.currencies.isFetching = true;
      state.currencies.error = null;
    });
    builder.addCase(getCurrenciesData.fulfilled, (state , action) => {
      state.currencies.error = null;
      state.currencies.isFetching = false;
      state.currencies.data = action.payload;
    });
    builder.addCase(getCurrenciesData.rejected, (state , action) => {
      state.currencies.isFetching = false;
      state.currencies.error = action.payload;
    });

    builder.addCase(getPlatformsData.pending, (state ) => {
      state.platforms.isFetching = true;
      state.platforms.error = null;
    });
    builder.addCase(getPlatformsData.fulfilled, (state , action) => {
      state.platforms.error = null;
      state.platforms.isFetching = false;
      state.platforms.data = action.payload;
    });
    builder.addCase(getPlatformsData.rejected, (state , action) => {
      state.platforms.isFetching = false;
      state.platforms.error = action.payload;
    });

    builder.addCase(getDeviceTypesData.pending, (state ) => {
      state.deviceTypes.isFetching = true;
      state.deviceTypes.error = null;
    });
    builder.addCase(getDeviceTypesData.fulfilled, (state , action) => {
      state.deviceTypes.error = null;
      state.deviceTypes.isFetching = false;
      state.deviceTypes.data = action.payload;
    });
    builder.addCase(getDeviceTypesData.rejected, (state , action) => {
      state.deviceTypes.isFetching = false;
      state.deviceTypes.error = action.payload;
    });
  },
});

export default summaryReportSlice.reducer;

export const {
  clearDownload,
  clearListState,
  clearColumnsState,
  clearAffiliatesState,
  clearTrackersState,
  reset,
} = summaryReportSlice.actions;
