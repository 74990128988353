//core
import React, { useState, useEffect, useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { format as dateFormat } from "date-fns";
import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";

//styles
import { useStyles } from "./styles";

//utils
import { getDataIds, getOptionsList, getStringIds, Options } from "../../../utils";

//routes
import routes from "../../../../../../navigation/routes";

//utils
import { getCookie } from "../../../../../../helpers/utils";

//constants
import { cookieSupervision } from "../../../../../../constants";

//hooks
import { useUI } from "../../../../../../store/common/ui/useUI";
import { usePermissions } from "../../../../../../store/common/configuration/usePermissions";

//components
import CSelect from "../../../../../UI/Fields/CSelect";
import { ISummaryReportFilterDataType as IFilterData } from "../../types";
import { OrderType } from "../../../../../affiliate/Trackers";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";

interface IDownloadReportProps {
  order: OrderType;
  columns: TableColumnsItem[];
  downloadOnThisPage: boolean;
  downloadData: any,
  filter: IFilterData;
  encodingTypes: string[];
  separatorsForFormat: {
    [key: string]: string[];
  };
  setToggleModal: (arg: boolean) => void;
  setDownload: (data: any) => void;
  clearDownloadState: () => void;
}


const DownloadReport: React.FC<IDownloadReportProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const {
    order,
    columns,
    downloadOnThisPage = false,
    downloadData,
    filter,
    encodingTypes,
    separatorsForFormat,
    setToggleModal,
    setDownload,
    clearDownloadState,
  } = props;

  const { setGeneralSnackbar } = useUI();
  const { hasPermissions } = usePermissions();

  const [formatList, setFormatList] = useState<Options[] | null>(null);
  const [separatorList, setSeparatorList] = useState<Options[] | null>(null);
  const [encodingList, setEncodingList] = useState<Options[] | null>(null);

  const [format, setFormat] = useLocalStorage('dwnl-format', '');
  const [separator, setSeparator] = useLocalStorage<string>('dwnl-separator', '');
  const [encoding, setEncoding] = useLocalStorage<string>('dwnl-encoding', '');

  useEffect(() => {
    if (!!encodingTypes) {
      setEncodingList(getOptionsList(encodingTypes));
    }
    if (!encoding) {
      setEncoding(encodingTypes[0]);
    }
  }, [encodingTypes]);

  useEffect(() => {
    const formatKeys = Object.keys(separatorsForFormat);
    const formatOptions = getOptionsList(formatKeys);

    if (!!separatorsForFormat) {
      setFormatList(formatOptions);
    }

    if (!format) {
      setFormat(formatOptions[0].id);
    } else {
      setSeparatorList(getOptionsList(separatorsForFormat[format]));

      if (!separator) {
        setSeparator(separatorsForFormat[format][0]);
      } else {
        if (!separatorsForFormat[format].includes(separator)) {
          setSeparator(separatorsForFormat[format][0]);
        }
      }
    }
  }, [separatorsForFormat, format]);

  useEffect(() => {
    const isSupervision = JSON.parse(getCookie(cookieSupervision));

    if (!!downloadData) {
      clearDownloadState();
      setToggleModal(false);
      if (downloadOnThisPage) {
        if (downloadData.hasOwnProperty('link')) {
          window.location = downloadData.link;
        }
      } else {
        setTimeout(() => {
          if (isSupervision) {
            setGeneralSnackbar({
              open: true,
              type: 'success',
              message: 'success',
              messageKey: 'common.messages.report_created',
            })
          } else {
            window.open(`${routes.admin.downloadReport.root}`, "_blank");
          }
        }, 100);
      }
    }
  }, [downloadData]);

  const handleChangeFormatSelect = useCallback((event: SelectChangeEvent) => {
      setFormat(event.target.value);
    }, [setFormat]);

  const handleChangeSeparatorSelect = useCallback((event: SelectChangeEvent) => {
    setSeparator(event.target.value);
  }, [setSeparator]);

  const handleChangeEncodingSelect = useCallback((event: SelectChangeEvent) => {
    setEncoding(event.target.value);
  }, [setEncoding]);

  const handleCancel = useCallback(() => {
      setToggleModal(false);
  }, [setToggleModal]);

  const handleSubmit = () => {
    const orderedColumns = columns.reduce((acc: string[], item) => {
      if (item.checked) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    const payload = {
      order: order,
      ...(hasPermissions(['api3.admin.playercommissionreportcontroller.sort_columns']) ? { orderedColumns: orderedColumns } : {}),
      report: {
        period: {
          start: dateFormat(new Date(filter.sent_from), 'yyyy-MM-dd'),
          end: dateFormat(new Date(filter.sent_to), 'yyyy-MM-dd'),
        },
        group_by: {
          fields: getStringIds(filter.group_by.fields),
        },
        //filter fields
        productIds: !!filter.productIds ? getDataIds(filter.productIds) : [],
        platformIds: !!filter.platformIds ? getDataIds(filter.platformIds) : [],
        managerIds: !!filter.managerIds ? getDataIds(filter.managerIds) : [],
        affiliateIds: !!filter.affiliateIds ? getDataIds(filter.affiliateIds) : [],
        trackerIds: !!filter.trackerIds ? getDataIds(filter.trackerIds) : [],
        creativeIds: !!filter.creativeIds ? getDataIds(filter.creativeIds) : [],
        brands: !!filter.brands ? getDataIds(filter.brands) : [],
        currencies: !!filter.currencies ? getDataIds(filter.currencies) : [],
        dealTypes: !!filter.dealTypes ? getDataIds(filter.dealTypes) : [],
        deviceTypes: !!filter.deviceTypes ? getDataIds(filter.deviceTypes) : [],
        ...(!!filter.registrationFrom || !!filter.registrationTo ? {
          registrationPeriod: {
            from: !!filter.registrationFrom ? dateFormat(new Date(filter.registrationFrom), 'yyyy-MM-dd') : null,
            to: !!filter.registrationTo ? dateFormat(new Date(filter.registrationTo), 'yyyy-MM-dd') : null,
          },
        } : {}),
        ...(!!filter.firstDepositFrom || !!filter.firstDepositTo ? {
          firstDepositPeriod: {
            from: !!filter.firstDepositFrom ? dateFormat(new Date(filter.firstDepositFrom), 'yyyy-MM-dd') : null,
            to: !!filter.firstDepositTo ? dateFormat(new Date(filter.firstDepositTo), 'yyyy-MM-dd') : null,
          },
        } : {}),
        ...(!!filter.anid.length ? {
          anid: filter.anid,
        } : {}),
        ...(!!filter.playerId.length ? {
          playerId: filter.playerId,
        } : {}),
        ...(!!filter.source1.length ? {
          source1: filter.source1,
        } : {}),
        ...(!!filter.source2.length ? {
          source2: filter.source2,
        } : {}),
        ...(!!filter.source3.length ? {
          source3: filter.source3,
        } : {}),
        ...(!!filter.source4.length ? {
          source4: filter.source4,
        } : {}),
        ...(!!filter.source5.length ? {
          source5: filter.source5,
        } : {}),

        /*...( searchValue.length >= 2 ? {
          searchField: {
            key: searchFilter,
            value: searchValue,
          }
        } : {}),*/

        format_file: format,
        encoding: encoding,
        separator: separator,
      }
    };

    setDownload(payload);
  }

  return (
    <>
      <Typography sx={{ mb: { xs: 2, sm: 3 } }} variant="h6" component="div">
        {t("affiliate.reports.download.title")}
      </Typography>
      <Box>
        {!!formatList && <Box sx={{ mb: 3 }}>
          <CSelect
            name="format"
            label={t("affiliate.reports.download.format")}
            value={format}
            options={formatList}
            onChange={handleChangeFormatSelect}
          />
        </Box>}

        {!!separatorList && <Box sx={{ mb: 3 }}>
          <CSelect
            name="separator"
            label={t("affiliate.reports.download.separator")}
            value={separator}
            options={separatorList}
            onChange={handleChangeSeparatorSelect}
          />
        </Box>}

        {!!encodingList && <Box sx={{ mb: 3 }}>
          <CSelect
            name="encoding"
            label={t("affiliate.reports.download.encoding")}
            value={encoding}
            options={encodingList}
            onChange={handleChangeEncodingSelect}
          />
        </Box>}
      </Box>
      <Box className={styles.actions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          {t("common.buttons.download")}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
        >
          {t("common.buttons.cancel")}
        </Button>
      </Box>
    </>
  );
};

export default React.memo(DownloadReport, arePropsEqual);
