export const BRAND = process.env.REACT_APP_BRAND;
export const TITLE = process.env.REACT_APP_TITLE;
export const DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
export const LOGIN_TITLE = process.env.REACT_APP_LOGIN_TITLE;
export const LOGIN_DESCRIPTION = process.env.REACT_APP_LOGIN_DESCRIPTION;


export const root = process.env.REACT_APP_API_URL;
export const baseUrl = `${root}api2/`;
export const baseUrlApi3 = `${root}api3/`;

export const api = {
  translations: `${baseUrlApi3}anonym/translate/locals_list`,
  config: `${root}env/config.json`,
  csrf_token: `${baseUrl}csrf_token`,
  upload_config: `${baseUrl}upload_config`,
  sign_in: `${baseUrl}sign_in`,
  sign_in_with_email: `${baseUrlApi3}sign_in`,
  sign_out: `${baseUrl}sign_out`,
  reg_traffic_source: `${baseUrl}traffic_source`,
  reg_country: `${baseUrl}country`,
  registrations: `${baseUrlApi3}anonym/registrations?error_keys=1`,
  confirmation: `${baseUrlApi3}confirmation/`,
  registrations_referrals: `${baseUrl}anonym/registrations/referrals_token`,
  recoveryPassword: `${baseUrl}password`,
  confirmRecoveryPassword: `${baseUrl}password/edit`,
  changePassword: `${baseUrl}password`,
  invite_admin: `${baseUrl}password`,
  invite_partner: `${baseUrlApi3}anonym/password/setup`,
  resend_invite_partner: `${baseUrlApi3}anonym/regenerate/{confirmationToken}`,

  //common
  permission: `${baseUrl}anonym/permission/list`,
  language: `${baseUrl}anonym/translate/locale`,

  // Two Factor Authentication
  two_factor_auth: {
    email: {
      resend_password: `${baseUrlApi3}auth/v1/2fa/email/resend`,
      enable_2fa: `${baseUrlApi3}auth/v1/2fa/email`,
      login_check: `${baseUrlApi3}auth/v1/2fa/check`,
    },
    otp: {
      enable_2fa: `${baseUrlApi3}auth/v1/2fa/google`,
    },
    verify_password: `${baseUrlApi3}auth/v1/2fa/{type}`,
    make_default: `${baseUrlApi3}auth/v1/2fa/{factor}/mark-default`,
  },

  //affiliate
  affiliate: {
    dashboard: {
      personal_chief: `${baseUrl}affiliate/dashboard/personal_chief`,
      get_stats: `${baseUrl}affiliate/dashboard/get_stats`,
    },
    dashboardV2: {
      widgets: {
        list: `${baseUrlApi3}affiliate/dashboard/v1/widgets`,
        getData: `${baseUrlApi3}affiliate/dashboard/v1/widgets/{{name}}`,
        getSettings: `${baseUrlApi3}affiliate/dashboard/v1/widgets/{{name}}/settings`,
        filters: `${baseUrlApi3}affiliate/dashboard/v1/widgets/general/filters`,
        creatives: `${baseUrlApi3}affiliate/dashboard/v1/filter/creatives`,
        trackers: `${baseUrlApi3}affiliate/dashboard/v1/filter/trackers`,
        brands: `${baseUrlApi3}affiliate/dashboard/v1/filter/brands`,
      },
    },
    trackers: {
      list: `${baseUrlApi3}affiliate/tracker/list`,
      details: `${baseUrl}affiliate/trackers`,
      create: `${baseUrl}affiliate/trackers/`,
      editName: `${baseUrl}affiliate/trackers/`,
      defaultCondition: `${baseUrl}affiliate/conditions/default_show`,
      conditionConst: `${baseUrl}affiliate/conditions/list_constants`,
      archive: `${baseUrl}affiliate/trackers/{trackerId}/archive`,
      unarchive: `${baseUrl}affiliate/trackers/{trackerId}/unarchive`,
      trackingLinks: {
        filter: `${baseUrlApi3}affiliate/creatives/filters`,
        list: `${baseUrlApi3}affiliate/creatives/list`,
        myList: `${baseUrlApi3}affiliate/creatives/list_with_links`,
        show_create: `${baseUrlApi3}affiliate/tracking_links/show_create`,
        saveTrackingLinks: `${baseUrlApi3}affiliate/tracking_links`,
        editTrackingLink: `${baseUrlApi3}affiliate/tracking_links/{id}/show_edit`,
        deleteTrackingLink: `${baseUrlApi3}affiliate/tracking_links/{id}/delete`,
      },
    },
    summary_report: {
      options: `${baseUrl}affiliate/reports/options`,
      generate_report: `${baseUrl}affiliate/reports/generate_report/v2`,
      download_report: `${baseUrl}affiliate/reports/download_report/v2`,
      segments_list: `${baseUrl}affiliate/reports/segments/list/v2`,
      delete_segment: `${baseUrl}affiliate/reports/segments`,
      create_segment: `${baseUrl}affiliate/reports/segments`,
      update_segment: `${baseUrl}affiliate/reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}affiliate/reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}affiliate/reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}affiliate/reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    summary_report_v2: {
      generate_report: `${baseUrlApi3}affiliate/summary_report/generate`,
      columns: `${baseUrlApi3}affiliate/summary_report/config/sortable_columns`,
      options: `${baseUrlApi3}affiliate/summary_report/form_options`,
      products: `${baseUrlApi3}affiliate/summary_report/filter/products`,
      trackers: `${baseUrlApi3}affiliate/summary_report/filter/trackers`,
      deals: `${baseUrlApi3}affiliate/summary_report/filter/deals`,
      creatives: `${baseUrlApi3}affiliate/summary_report/filter/creatives`,
      brands: `${baseUrlApi3}affiliate/summary_report/filter/brands`,
      currencies: `${baseUrlApi3}affiliate/summary_report/filter/currencies`,
      platforms: `${baseUrlApi3}affiliate/summary_report/filter/platforms`,
      download_report: `${baseUrlApi3}affiliate/summary_report/download`,
    },
    creative_report: {
      options: `${baseUrl}affiliate/creative_reports/options`,
      generate_report: `${baseUrl}affiliate/creative_reports/generate_report`,
      download_report: `${baseUrl}affiliate/creative_reports/download_report`,
      segments_list: `${baseUrl}affiliate/creative_reports/segments/list`,
      delete_segment: `${baseUrl}affiliate/creative_reports/segments`,
      create_segment: `${baseUrl}affiliate/creative_reports/segments`,
      update_segment: `${baseUrl}affiliate/creative_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}affiliate/creative_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}affiliate/creative_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}affiliate/creative_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    subaffiliate_report: {
      options: `${baseUrl}affiliate/subaffiliate_reports/options`,
      generate_report: `${baseUrl}affiliate/subaffiliate_reports/generate_report`,
      download_report: `${baseUrl}affiliate/subaffiliate_reports/download_report`,
      segments_list: `${baseUrl}affiliate/subaffiliate_reports/segments/list`,
      delete_segment: `${baseUrl}affiliate/subaffiliate_reports/segments`,
      create_segment: `${baseUrl}affiliate/subaffiliate_reports/segments`,
      update_segment: `${baseUrl}affiliate/subaffiliate_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}affiliate/subaffiliate_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}affiliate/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}affiliate/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    download_report: {
      list: `${baseUrl}affiliate/background_reports/list`,
      delete: `${baseUrl}affiliate/background_reports/{reportId}`,
      download: `${baseUrl}affiliate/background_reports/{reportId}/download_report_link`,
    },
    settings: {
      edit: `${baseUrlApi3}affiliate/profile`,
      update: `${baseUrlApi3}affiliate/profile`,
      update_email: `${baseUrl}affiliate/settings/email_update`,
      update_password: `${baseUrl}affiliate/settings/password_update`,
      payment: {
        list: `${baseUrlApi3}affiliate/payment-data`,
        methods: `${baseUrlApi3}affiliate/payment-method`,
        create: `${baseUrlApi3}affiliate/payment-data`,
        delete: `${baseUrlApi3}affiliate/payment-data/{id}`,
        update: `${baseUrlApi3}affiliate/payment-data/{id}`,
        history: `${baseUrlApi3}affiliate/payment-data-log`
      },
      balance: `${baseUrlApi3}affiliate/current-balance`,
    },
    api: {
      statistics: `${baseUrl}affiliate/settings/api_token`
    },
    s2s: {
      root: `${baseUrlApi3}affiliate/webhook`,
      list: `${baseUrlApi3}affiliate/webhook/list`,
      create: `${baseUrlApi3}affiliate/webhook/create`,
      copy: `${baseUrlApi3}affiliate/webhook/{id}/copy`,
      remove: `${baseUrlApi3}affiliate/webhook/{id}`,
      activate: `${baseUrlApi3}affiliate/webhook/{id}/activate`,
      deactivate: `${baseUrlApi3}affiliate/webhook/{id}/deactivate`,
      webhook: `${baseUrlApi3}affiliate/webhook/{webhookId}`,
      url_option: `${baseUrlApi3}affiliate/webhook/{urlOptionId}/url-option`,
      update_url_option: `${baseUrlApi3}affiliate/webhook/{urlOptionId}/url-option/{idUrlOption}`,
      delete_url_option: `${baseUrlApi3}affiliate/webhook/{urlOptionId}/url-option/{idUrlOption}`,
    },
    reference: {
      token: `${baseUrl}affiliate/settings/referrals_token`,
      conditions: `${baseUrl}affiliate/settings/show_subaffiliate_condition`,
    },
    new_creative_report: {
      generate_report: `${baseUrlApi3}affiliate/creative_click_report/generate`,
      options: `${baseUrlApi3}affiliate/creative_click_report/form_options`,
      trackers: `${baseUrlApi3}affiliate/creative_click_report/filter/trackers`,
      creatives: `${baseUrlApi3}affiliate/creative_click_report/filter/creatives`,
      download_report: `${baseUrlApi3}affiliate/report/download_creative_report`,
    },
    offers: {
      filters: `${baseUrlApi3}affiliate/offer/filters`,
      list: `${baseUrlApi3}affiliate/offer/list`,
      request: `${baseUrlApi3}affiliate/offer/request`,
    },
    paymentHistory: {
      widgets: `${baseUrlApi3}affiliate/payment-history/summary-cards`,
      list: `${baseUrlApi3}affiliate/payment-history/list`,
    }
  },

  supervision: {
    settings: {
      edit: `${baseUrlApi3}admin/supervision/affiliate/profile`,
      payment: {
        list: `${baseUrlApi3}admin/supervision/affiliate/payment-data`,
        methods: `${baseUrlApi3}admin/supervision/affiliate/payment-method`,
        create: `${baseUrlApi3}admin/supervision/affiliate/payment-data`,
        delete: `${baseUrlApi3}admin/supervision/affiliate/payment-data/{id}`,
        update: `${baseUrlApi3}admin/supervision/affiliate/payment-data/{id}`,
        history: `${baseUrlApi3}affiliate/payment-data-log`
      },
      balance: `${baseUrlApi3}admin/supervision/affiliate/current-balance`,
    },
    dashboard: {
      personal_chief: `${baseUrl}admin/supervision/affiliate/dashboard/personal_chief`,
      get_stats: `${baseUrl}admin/supervision/affiliate/dashboard/get_stats`,
    },
    dashboardV2: {
      widgets: {
        list: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/widgets`,
        getData: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/widgets/{{name}}`,
        getSettings: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/widgets/{{name}}/settings`,
        filters: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/widgets/general/filters`,
        creatives: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/filter/creatives`,
        trackers: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/filter/trackers`,
        brands: `${baseUrlApi3}admin/supervision/affiliate/dashboard/v1/filter/brands`,
      },
    },
    trackers: {
      list: `${baseUrlApi3}admin/supervision/affiliate/tracker/list`,
      details: `${baseUrl}admin/supervision/affiliate/trackers`,
      create: `${baseUrl}admin/supervision/affiliate/trackers/`,
      editName: `${baseUrl}admin/supervision/affiliate/trackers/`,
      defaultCondition: `${baseUrl}admin/supervision/affiliate/conditions/default_show`,
      conditionConst: `${baseUrl}admin/supervision/affiliate/conditions/list_constants`,
      archive: `${baseUrlApi3}admin/supervision/affiliate/trackers/{trackerId}/archive`,
      unarchive: `${baseUrlApi3}admin/supervision/affiliate/trackers/{trackerId}/unarchive`,
      trackingLinks: {
        filter: `${baseUrlApi3}admin/supervision/affiliate/creatives/filters`,
        list: `${baseUrlApi3}admin/supervision/affiliate/creatives/list`,
        myList: `${baseUrlApi3}admin/supervision/affiliate/creatives/list_with_links`,
        show_create: `${baseUrlApi3}admin/supervision/affiliate/tracking_links/show_create`,
        saveTrackingLinks: `${baseUrlApi3}admin/supervision/affiliate/tracking_links`,
        editTrackingLink: `${baseUrlApi3}admin/supervision/affiliate/tracking_links/{id}/show_edit`,
        deleteTrackingLink: `${baseUrlApi3}admin/supervision/affiliate/tracking_links/{id}/delete`,
      },
    },
    summary_report: {
      options: `${baseUrl}admin/supervision/affiliate/reports/options`,
      generate_report: `${baseUrl}admin/supervision/affiliate/reports/generate_report/v2`,
      download_report: `${baseUrl}admin/supervision/affiliate/reports/download_report/v2`,
      segments_list: `${baseUrl}admin/supervision/affiliate/reports/segments/list/v2`,
      delete_segment: `${baseUrl}admin/supervision/affiliate/reports/segments`,
      create_segment: `${baseUrl}admin/supervision/affiliate/reports/segments`,
      update_segment: `${baseUrl}admin/supervision/affiliate/reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/supervision/affiliate/reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/supervision/affiliate/reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/supervision/affiliate/reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    summary_report_v2: {
      generate_report: `${baseUrlApi3}admin/supervision/affiliate/summary_report/generate`,
      columns: `${baseUrlApi3}admin/supervision/affiliate/summary_report/config/sortable_columns`,
      options: `${baseUrlApi3}admin/supervision/affiliate/summary_report/form_options`,
      products: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/products`,
      trackers: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/trackers`,
      deals: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/deals`,
      creatives: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/creatives`,
      brands: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/brands`,
      currencies: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/currencies`,
      platforms: `${baseUrlApi3}admin/supervision/affiliate/summary_report/filter/platforms`,
      download_report: `${baseUrlApi3}admin/supervision/affiliate/summary_report/download`,
    },
    creative_report: {
      options: `${baseUrl}admin/supervision/affiliate/creative_reports/options`,
      generate_report: `${baseUrl}admin/supervision/affiliate/creative_reports/generate_report`,
      download_report: `${baseUrl}admin/supervision/affiliate/creative_reports/download_report`,
      segments_list: `${baseUrl}admin/supervision/affiliate/creative_reports/segments/list`,
      delete_segment: `${baseUrl}admin/supervision/affiliate/creative_reports/segments`,
      create_segment: `${baseUrl}admin/supervision/affiliate/creative_reports/segments`,
      update_segment: `${baseUrl}admin/supervision/affiliate/creative_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/supervision/affiliate/creative_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/supervision/affiliate/creative_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/supervision/affiliate/creative_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    new_creative_report: {
      generate_report: `${baseUrlApi3}admin/supervision/affiliate/creative_click_report/generate`,
      options: `${baseUrlApi3}admin/supervision/affiliate/creative_click_report/form_options`,
      trackers: `${baseUrlApi3}admin/supervision/affiliate/creative_click_report/filter/trackers`,
      creatives: `${baseUrlApi3}admin/supervision/affiliate/creative_click_report/filter/creatives`,
      download_report: `${baseUrlApi3}admin/supervision/affiliate/report/download_creative_report`,
    },
    subaffiliate_report: {
      options: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/options`,
      generate_report: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/generate_report`,
      download_report: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/download_report`,
      segments_list: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments/list`,
      delete_segment: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments`,
      create_segment: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments`,
      update_segment: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/supervision/affiliate/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    download_report: {
      list: `${baseUrl}admin/supervision/affiliate/background_reports/list`,
      delete: `${baseUrl}admin/supervision/affiliate/background_reports/{reportId}`,
      download: `${baseUrl}admin/supervision/affiliate/background_reports/{reportId}/download_report_link`,
    },
    api: {
      statistics: `${baseUrl}admin/supervision/affiliate/settings/api_token`
    },
    s2s: {
      root: `${baseUrlApi3}admin/supervision/affiliate/webhook`,
      list: `${baseUrlApi3}admin/supervision/affiliate/webhook/list`,
      create: `${baseUrlApi3}admin/supervision/affiliate/webhook/create`,
      copy: `${baseUrlApi3}admin/supervision/affiliate/webhook/{id}/copy`,
      remove: `${baseUrlApi3}admin/supervision/affiliate/webhook/{id}`,
      activate: `${baseUrlApi3}admin/supervision/affiliate/webhook/{id}/activate`,
      deactivate: `${baseUrlApi3}admin/supervision/affiliate/webhook/{id}/deactivate`,
      webhook: `${baseUrlApi3}admin/supervision/affiliate/webhook/{webhookId}`,
      url_option: `${baseUrlApi3}admin/supervision/affiliate/webhook/{urlOptionId}/url-option`,
      update_url_option: `${baseUrlApi3}admin/supervision/affiliate/webhook/{urlOptionId}/url-option/{idUrlOption}`,
      delete_url_option: `${baseUrlApi3}admin/supervision/affiliate/webhook/{urlOptionId}/url-option/{idUrlOption}`,
    },
    reference: {
      token: `${baseUrl}admin/supervision/affiliate/settings/referrals_token`,
      conditions: `${baseUrl}admin/supervision/affiliate/settings/show_subaffiliate_condition`,
    },
    offers: {
      filters: `${baseUrlApi3}admin/supervision/affiliate/offer/filters`,
      list: `${baseUrlApi3}admin/supervision/affiliate/offer/list`,
      request: `${baseUrlApi3}admin/supervision/affiliate/offer/request`,
    },
    paymentHistory: {
      widgets: `${baseUrlApi3}admin/supervision/affiliate/payment-history/summary-cards`,
      list: `${baseUrlApi3}admin/supervision/affiliate/payment-history/list`,
    }
  },

  //applicant
  applicant: {
    status: `${baseUrlApi3}applicant/status`,
    resend_confirmation: `${baseUrlApi3}applicant/resend-confirmation`,
  },

  //admin
  admin: {
    dashboard: {
      get_stats: `${baseUrl}admin/dashboard/get_stats`,
    },
    dashboardV2: {
      widgets: {
        list: `${baseUrlApi3}admin/dashboard/v1/widgets`,
        getData: `${baseUrlApi3}admin/dashboard/v1/widgets/{{name}}`,
        getSettings: `${baseUrlApi3}admin/dashboard/v1/widgets/{{name}}/settings`,
        filters: `${baseUrlApi3}admin/dashboard/v1/widgets/general/filters`,
        affiliates: `${baseUrlApi3}admin/dashboard/v1/filter/affiliates`,
        creatives: `${baseUrlApi3}admin/dashboard/v1/filter/creatives`,
        trackers: `${baseUrlApi3}admin/dashboard/v1/filter/trackers`,
        brands: `${baseUrlApi3}admin/dashboard/v1/filter/brands`,
      }
    },
    creatives: {
      form: `${baseUrlApi3}creatives/create`,
      create: `${baseUrlApi3}creatives`,
      edit: `${baseUrlApi3}creatives/`,
      delete: `${baseUrlApi3}creatives/{id}/delete`,
      get: `${baseUrlApi3}creatives/edit/`,
      filters: `${baseUrlApi3}creatives/filters`,
      list: `${baseUrlApi3}creatives/list`,
      toggle_hide: `${baseUrlApi3}creatives/toggle_hide`,
      brands_list: `${baseUrlApi3}admin/filters/brands`,
      copy: `${baseUrlApi3}creatives/{id}/copy`,
      brand_mirror: `${baseUrlApi3}admin/brand/{brandId}/mirror`,
      activate_mirror: `${baseUrlApi3}admin/brand/{brandId}/mirror_settings`,
    },
    creatives_v2: {
      form: `${baseUrlApi3}creative/v1/creatives/create`,
      create: `${baseUrlApi3}creative/v1/creatives`,
      edit: `${baseUrlApi3}creative/v1/creatives/`,
      delete: `${baseUrlApi3}creative/v1/creatives/{id}`,
      get: `${baseUrlApi3}creative/v1/creatives/`,
      filters: `${baseUrlApi3}creative/v1/creatives/filters`,
      list: `${baseUrlApi3}creative/v1/creatives/list`,
      toggle_hide: `${baseUrlApi3}creative/v1/creatives/{id}/toggle_hide`,
      brands_list: `${baseUrlApi3}admin/filters/brands`,
      copy: `${baseUrlApi3}creative/v1/creatives/{id}/copy`,
      brand_mirror: `${baseUrlApi3}admin/brand/{brandId}/mirror`,
      activate_mirror: `${baseUrlApi3}admin/brand/{brandId}/mirror_settings`,
      creatives_options: `${baseUrlApi3}creative/v1/creatives/filters/creatives`,
    },
    reports: {
      finance_report: {
        list: `${root}api3/finance_report/list`,
        detailed: {
          root: `${root}api3/finance_report/detailed/`,
          adjustment: {
            list: `${root}api3/finance_report/detailed/adjustment/list`,
            reasons: `${root}api3/finance_report/detailed/adjustment/reasons`,
            add: `${root}api3/finance_report/detailed/adjustment/add`,
            edit: `${root}api3/finance_report/detailed/adjustment/edit`,
            approve: `${root}api3/finance_report/detailed/adjustment/approve`,
            approve_paid: `${root}api3/finance_report/detailed/adjustment/approve_paid`,
            approve_cover_prepayment_paid: `${root}api3/finance_report/detailed/adjustment/approve_cover_prepayment`,
            deleted: `${root}api3/finance_report/detailed/adjustment/delete`,
            brands: `${root}api3/finance_report/detailed/adjustment/brands`
          },
          payment: {
            change: `${baseUrlApi3}finance_report/detailed/payment/change`,
          },
          managerApprove: `${baseUrlApi3}finance_report/detailed/{reportId}/approve`,
          cancelManagerApprove: `${baseUrlApi3}finance_report/detailed/{reportId}/cancel_approve`,
          finManagerApprove: `${baseUrlApi3}finance_report/detailed/{reportId}/approve_finance`,
          toPaidApprove: `${baseUrlApi3}finance_report/detailed/{reportId}/to_paid`,
          processingPayment: `${baseUrlApi3}finance_report/detailed/{reportId}/processing_payment`,
          cancelProcessingPayment: `${baseUrlApi3}finance_report/detailed/{reportId}/cancel_processing_payment`,
          carryover: `${baseUrlApi3}finance_report/detailed/{reportId}/carryover`,
          cancelCarryover: `${baseUrlApi3}finance_report/detailed/{reportId}/cancel_carryover`,
          download: `${baseUrlApi3}finance_report/detailed/{reportId}/download`,
          download_with_logs: `${baseUrlApi3}finance_report/detailed/{reportId}/download_with_logs`,
          changeComment: `${baseUrlApi3}finance_report/detailed/{reportId}/change_comment`,
          brands: `${baseUrlApi3}finance_report/filters/brands`,
          showReportRow: `${baseUrlApi3}finance_report/detailed/{reportId}/show_report_row`,
          brandStatistics: `${baseUrlApi3}finance_report/detailed/{reportId}/brand_statistics`,
          columns: `${baseUrlApi3}finance_report/config/sortable_columns`,
          addPayoutRow: `${baseUrlApi3}finance_report/detailed/{periodId}/report_row`,
          payoutsList: `${baseUrlApi3}finance_report/detailed/payment/list`,
          createUpdatePayout: `${baseUrlApi3}finance_report/detailed/payment`,
          availablePayments: `${baseUrlApi3}finance_report/detailed/{periodId}/affiliate/{affiliateId}/payment_data`,
          logs:`${baseUrlApi3}finance_report/detailed/{$periodId}/action_logs`,
          invoice: `${baseUrlApi3}finance_report/detailed/{periodId}/report_row/{affiliateId}/invoice`,
          periodsList:`${baseUrlApi3}finance_report/filters/periods`,
          convert_currency:`${baseUrlApi3}finance_report/detailed/{reportId}/convert_currencies`,
        },
        filters: `${root}api3/finance_report/filters`,
      }
    },
    partners: {
      filters: `${baseUrlApi3}admin/affiliate/filters`,
      list: `${baseUrlApi3}admin/affiliate`,
      deals: `${baseUrlApi3}admin/filters/deals`,
      approve: `${baseUrlApi3}admin/affiliate/{affiliateId}/approve`,
      decline: `${baseUrlApi3}admin/affiliate/{affiliateId}/decline`,
      block: `${baseUrlApi3}admin/affiliate/{affiliateId}/block`,
      unblock: `${baseUrlApi3}admin/affiliate/{affiliateId}/unblock`,
      resend_confirmation: `${baseUrl}admin/affiliates/{affiliateId}/resend_confirmation_email`,
      affiliates: `${baseUrlApi3}admin/affiliate/filters/affiliates`,
      chiefs: `${baseUrlApi3}admin/affiliate/filters/chiefs`,
      chiefs_all: `${baseUrlApi3}admin/affiliate/filters/chiefs-all`,
      departments: `${baseUrlApi3}admin/affiliate/filters/departments`,
      platforms: `${baseUrlApi3}admin/filters/platforms`,
      create_partner: `${baseUrlApi3}admin/affiliate/applicant`,
      invitation_links: `${baseUrlApi3}admin/affiliate/filters/invitation_links`,
      invitation_managers: `${baseUrlApi3}admin/affiliate/filters/invitation_managers`,
      aff_deals: `${baseUrl}admin/affiliates/{affiliateId}/deal-options`,
    },
    affiliateProfile: {
      get: `${baseUrlApi3}admin/affiliate/{affiliateId}`,
      update: `${baseUrlApi3}admin/affiliate/{affiliateId}`,
      updateChiefs: `${baseUrlApi3}admin/affiliate/{affiliateId}/chiefs`,
      communication: {
        list: `${baseUrlApi3}admin/affiliate/{affiliateId}/communication/list`,
        create: `${baseUrlApi3}admin/affiliate/{affiliateId}/communication`,
        update: `${baseUrlApi3}admin/affiliate/{affiliateId}/communication/{communicationId}`,
        delete: `${baseUrlApi3}admin/affiliate/{affiliateId}/communication/{communicationId}`,
      },
      trafficStatus: {
        update: `${baseUrlApi3}admin/affiliate/{affiliateId}/traffic-status`,
      },
      payment: {
        list: `${baseUrlApi3}admin/affiliate-payment-data/{affiliateId}`,
        methods: `${baseUrlApi3}admin/payment-method/{affiliateId}`,
        create: `${baseUrlApi3}admin/affiliate-payment-data/{affiliateId}`,
        delete: `${baseUrlApi3}admin/affiliate-payment-data/{affiliateId}/{id}`,
        update: `${baseUrlApi3}admin/affiliate-payment-data/{affiliateId}/{id}`,
        history: `${baseUrlApi3}admin/affiliate-payment-data-log/{affiliateId}`
      }
    },
    deals: {
      updateDeal: `${baseUrl}admin/affiliates/{affiliateId}`,
      conditions: {
        get: `${baseUrl}admin/affiliates/{affiliateId}/show_subaffiliate_condition`,
        getHistoryRow: `${baseUrl}admin/history_of_subaffiliates_changes/{historyId}/edit`,
        update: `${baseUrl}admin/history_of_subaffiliates_changes/{id}`,
        create: `${baseUrl}admin/history_of_subaffiliates_changes`,
        delete: `${baseUrl}admin/history_of_subaffiliates_changes/{id}`,
        additional_settings: `${baseUrlApi3}admin/affiliate/{affiliateId}/additional-settings`,
      },
    },
    trackers: {
      list: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/list`,
      conditions: `${baseUrl}admin/affiliates/{affiliateId}/show_tracker_settings`,
      create: `${baseUrl}admin/affiliates/{affiliateId}/trackers/`,
      currentTracker: `${baseUrl}admin/affiliates/{affiliateId}/trackers/{trackerId}/show_current_tracker`,
      updateTrackerCondition: `${baseUrl}admin/affiliates/{affiliateId}/trackers/{trackerId}/update_condition`,
      deleteHistoryRow: `${baseUrl}admin/history_of_trackers_changes/{id}`,
      getHistoryRow: `${baseUrl}admin/history_of_trackers_changes/{id}/edit`,
      updateHistoryRow: `${baseUrl}admin/history_of_trackers_changes/{id}`,
      count: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/count`,
      updateTracker: `${baseUrl}admin/affiliates/{affiliateId}/trackers/{trackerId}`,
      details: `${baseUrl}admin/trackers/{trackerId}/details`,
      archive: `${baseUrl}admin/trackers/{trackerId}/archive`,
      unarchive: `${baseUrl}admin/trackers/{trackerId}/unarchive`,
      savePromocode: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/{trackerId}/promocode`,
      trackingLinks: {
        filter: `${baseUrlApi3}admin/affiliate/creatives/filters`,
        list: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/creatives`,
        myList: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/links`,
        show_create: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/tracking_links/form`,
        saveTrackingLinks: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracking_links`,
        editTrackingLink: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/tracking_links/{id}/form_with_data`,
        deleteTrackingLink: `${baseUrlApi3}admin/affiliate/{affiliateId}/tracker/tracking_links/{id}`,
      },
      additional_settings: `${baseUrlApi3}admin/tracker/{trackerId}/additional-settings`,
    },
    supervision: {
      enable: `${baseUrl}admin/affiliates/{affId}/enable_supervision`,
      disable: `${baseUrl}disable_supervision`,
    },
    invitationLinks: {
      root: `${baseUrlApi3}admin/affiliate/invitation-link`,
      createOptions: `${baseUrlApi3}admin/affiliate/invitation-link/create-options`,
      list: `${baseUrlApi3}admin/affiliate/invitation-link/list`,
    },
    summary_report: {
      options: `${baseUrl}admin/reports/options`,
      generate_report: `${baseUrl}admin/reports/generate_report/v3`,
      download_report: `${baseUrl}admin/reports/download_report/v3`,
      segments_list: `${baseUrl}admin/reports/segments/list/v2`,
      delete_segment: `${baseUrl}admin/reports/segments`,
      create_segment: `${baseUrl}admin/reports/segments`,
      update_segment: `${baseUrl}admin/reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    subaffiliate_report: {
      options: `${baseUrl}admin/subaffiliate_reports/options`,
      generate_report: `${baseUrl}admin/subaffiliate_reports/generate_report`,
      download_report: `${baseUrl}admin/subaffiliate_reports/download_report`,
      segments_list: `${baseUrl}admin/subaffiliate_reports/segments/list`,
      delete_segment: `${baseUrl}admin/subaffiliate_reports/segments`,
      create_segment: `${baseUrl}admin/subaffiliate_reports/segments`,
      update_segment: `${baseUrl}admin/subaffiliate_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/subaffiliate_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/subaffiliate_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    creative_report: {
      generate_report: `${baseUrlApi3}admin/creative_click_report/generate`,
      columns: `${baseUrlApi3}admin/creative_click_report/config/sortable_columns`,
      options: `${baseUrlApi3}admin/creative_click_report/form_options`,
      affiliates: `${baseUrlApi3}admin/creative_click_report/filter/affiliates`,
      trackers: `${baseUrlApi3}admin/creative_click_report/filter/trackers`,
      deals: `${baseUrlApi3}admin/creative_click_report/filter/deals`,
      creatives: `${baseUrlApi3}admin/creative_click_report/filter/creatives`,
      download_report: `${baseUrlApi3}admin/report/download_creative_report`,
    },
    summary_report_v2: {
      generate_report: `${baseUrlApi3}admin/summary_report/generate`,
      columns: `${baseUrlApi3}admin/summary_report/config/sortable_columns`,
      options: `${baseUrlApi3}admin/summary_report/form_options`,
      products: `${baseUrlApi3}admin/summary_report/filter/products`,
      managers: `${baseUrlApi3}admin/summary_report/filter/managers`,
      affiliates: `${baseUrlApi3}admin/summary_report/filter/affiliates`,
      trackers: `${baseUrlApi3}admin/summary_report/filter/trackers`,
      deals: `${baseUrlApi3}admin/summary_report/filter/deals`,
      creatives: `${baseUrlApi3}admin/summary_report/filter/creatives`,
      brands: `${baseUrlApi3}admin/summary_report/filter/brands`,
      currencies: `${baseUrlApi3}admin/summary_report/filter/currencies`,
      platforms: `${baseUrlApi3}admin/summary_report/filter/platforms`,
      download_report: `${baseUrlApi3}admin/summary_report/download`,
      device_types: `${baseUrlApi3}admin/summary_report/filter/devices`,
    },
    roles_and_permissions: {
      getNamespaces: `${baseUrl}admin/project_settings/list_report_fields`,
      getRoles: `${baseUrl}admin/roles/list`,
      createRole: `${baseUrl}admin/roles`,
      deleteRole: `${baseUrl}admin/roles/{roleId}`,
      updateRoleName: `${baseUrl}admin/roles/{roleId}`,
      getReportFields: `${baseUrl}admin/project_settings/report_fields`,
      addReportFields: `${baseUrl}admin/project_settings/add_report_fields`,
      removeReportFields: `${baseUrl}admin/project_settings/remove_report_fields`,
      getProductAccess: `${baseUrl}admin/project_settings/get_product_access/{roleId}`,
      addProductAccess: `${baseUrl}admin/project_settings/add_product_access/{roleId}`,
      removeProductAccess: `${baseUrl}admin/project_settings/remove_product_access/{roleId}`,
      getPermissionsTree: `${baseUrl}admin/roles/{roleId}/permissions/list`,
      savePermissions: `${baseUrl}admin/roles/{roleId}/permissions/save`,
      getPlatforms: `${baseUrlApi3}admin/platform-access/options`,
      getPlatformForRole: `${baseUrlApi3}admin/platform-access/{roleId}/list`,
      addPlatformForRole: `${baseUrlApi3}admin/platform-access/{roleId}`,
      removePlatformForRole: `${baseUrlApi3}admin/platform-access/{roleId}`,
    },
    download_report: {
      list: `${baseUrl}admin/background_reports/list`,
      delete: `${baseUrl}admin/background_reports/{reportId}`,
      download: `${baseUrl}admin/background_reports/{reportId}/download_report_link`,
    },
    webhooks_report: {
      options: `${baseUrl}admin/reports/options`,
      generate_report: `${baseUrl}admin/webhooks_tasks/list`,
      columns: `${baseUrl}admin/webhooks_tasks/config/sortable_columns`,
      download_report: `${baseUrl}admin/webhooks_tasks/list/download`,
      filter: `${baseUrl}admin/webhooks_tasks/filter_tabs/{filterLabel}/data`,
      details_report: `${baseUrl}admin/webhooks_tasks/logs`,
    },
    affProgramTrackers: {
      list: `${baseUrl}admin/trackers/list`
    },
    conditions: {
      list: `${baseUrl}admin/conditions/list`,
      delete: `${baseUrl}admin/conditions/{id}`,
      clone: `${baseUrl}admin/conditions/{id}/clone`,
      constants: `${baseUrl}admin/conditions/list_constants`,
      create: `${baseUrl}admin/conditions/0/change_with_nested_attributes`,
      edit: `${baseUrl}admin/conditions/{conditionId}/change_with_nested_attributes`,
      condition: `${baseUrl}admin/conditions/{conditionId}/edit`,
      offers_id: `${baseUrlApi3}admin/filters/offers`,
      offer_currency: `${baseUrlApi3}admin/offer/{offerId}/currencies`,
      archive: `${baseUrl}admin/conditions/{id}/archive`,
      unarchive: `${baseUrl}admin/conditions/{id}/unarchive`,
      rename: `${baseUrl}admin/conditions/{id}/rename`,
      brands_list: `${baseUrlApi3}admin/filters/brands-detailed`,
      filter_creator: `${baseUrl}admin/conditions/filter/creator`,
    },
    employees: {
      list: `${baseUrlApi3}admin/chief/list`,
      list_roles: `${baseUrl}admin/personals/list_roles`,
      list_departments: `${baseUrl}admin/personals/list_departments`,
      add_admin: `${baseUrl}admin/personals`,
      remove_admin: `${baseUrl}admin/personals/{adminId}`,
      invite_admin: `${baseUrl}admin/personals/{adminId}/resend_invite`,
      block_admin: `${baseUrl}admin/personals/{adminId}/change_ban_status`,
      edit_admin: `${baseUrl}admin/personals/{adminId}`,
      roles: `${baseUrl}admin/roles/names-list`
    },
    quints_api: {
      list:  `${baseUrl}admin/profile/api_tokens`,
    },
    old_creative_report: {
      options: `${baseUrl}admin/creative_reports/options`,
      generate_report: `${baseUrl}admin/creative_reports/generate_report/v3`,
      download_report: `${baseUrl}admin/creative_reports/download_report`,
      segments_list: `${baseUrl}admin/creative_reports/segments/list`,
      delete_segment: `${baseUrl}admin/creative_reports/segments`,
      create_segment: `${baseUrl}admin/creative_reports/segments`,
      update_segment: `${baseUrl}admin/creative_reports/segments/{segmentId}`,
      get_segment_tab: `${baseUrl}admin/creative_reports/segments/{segmentId}/tabs/`,
      add_segment_tab_data: `${baseUrl}admin/creative_reports/segments/{segmentId}/tabs/{tabId}/add`,
      remove_segment_tab_data: `${baseUrl}admin/creative_reports/segments/{segmentId}/tabs/{tabId}/remove`,
    },
    sub_aff_deals: {
      list: `${baseUrl}admin/subaffiliate_conditions/list`,
      delete: `${baseUrl}admin/subaffiliate_conditions/{id}`,
      constants: `${baseUrl}admin/subaffiliate_conditions/list_constants`,
      create: `${baseUrl}admin/subaffiliate_conditions/0/change_with_nested_attributes`,
      edit: `${baseUrl}admin/subaffiliate_conditions/{id}/change_with_nested_attributes`,
      condition: `${baseUrl}admin/subaffiliate_conditions/{id}/edit`,
    },
    players_list: {
      list: `${baseUrl}admin/daily_statistics_players`,
      players_profile: `${baseUrl}admin/daily_statistics_players/{playerId}`,
    },
    mailers_manager: {
      list: `${baseUrl}admin/mailers_manager/list`,
      edit: `${baseUrl}admin/mailers_manager/edit`,
      save: `${baseUrl}admin/mailers_manager/save`,
      delete: `${baseUrl}admin/mailers_manager/delete`,
      send_test: `${baseUrl}admin/mailers_manager/send-test`
    },
    offers: {
      filters: `${baseUrlApi3}admin/offer/filters`,
      list: `${baseUrlApi3}admin/offer/list`,
      create: `${baseUrlApi3}admin/offer`,
      get: `${baseUrlApi3}admin/offer/{id}`,
      update: `${baseUrlApi3}admin/offer/{id}`,
      delete: `${baseUrlApi3}admin/offer/{id}`,
      copy: `${baseUrlApi3}admin/offer/{id}`,
      createOptions: `${baseUrlApi3}admin/offer/create-options`,
    },
    brands: {
      filters: `${baseUrlApi3}admin/brand/create-options`,
      list: `${baseUrlApi3}admin/brand/list`,
      create: `${baseUrlApi3}admin/brand`,
      get: `${baseUrlApi3}admin/brand/{code}`,
      update: `${baseUrlApi3}admin/brand/{code}`,
      delete: `${baseUrlApi3}admin/brand/{code}`,
      createOptions: `${baseUrlApi3}admin/brand/create-options`,
      archive: `${baseUrlApi3}admin/brand/{code}/archive`,
      unarchive: `${baseUrlApi3}admin/brand/{code}/unarchive`,
    },
    billing: {
      package: `${baseUrlApi3}admin/billing/usage`,
      package_by_brand: `${baseUrlApi3}admin/billing/usage/by-brand/{id}`,
      create_update: `${baseUrlApi3}admin/billing/plan`,
      history_changes: `${baseUrlApi3}admin/billing/plan/history`,
    },
    account: {
      profile: `${baseUrlApi3}admin/my-profile`,
      password: `${baseUrlApi3}admin/my-profile/password`,
    }
  },

  fields: {
    affiliates: `${baseUrlApi3}admin/filters/affiliates`,
    chiefs: `${baseUrlApi3}admin/filters/chiefs`,
    departments: `${baseUrlApi3}admin/filters/departments`,
    platforms: `${baseUrlApi3}admin/filters/platforms`,
    brands_list: `${baseUrlApi3}admin/filters/brands`,
    trackers: `${baseUrlApi3}admin/filters/trackers`,
    webhooks: `${baseUrlApi3}admin/filters/webhooks`,
    offers: `${baseUrlApi3}admin/filters/offers`,
    operators: `${baseUrlApi3}admin/filters/operator-ids`,
  },

  storage: {
    list: `${baseUrlApi3}user/storage/{{type}}/{{target}}/list`,
    create: `${baseUrlApi3}user/storage/{{type}}/{{target}}`,
    get: `${baseUrlApi3}user/storage/{{type}}/{{target}}/{{id}}`,
    update: `${baseUrlApi3}user/storage/{{type}}/{{target}}/{{id}}`,
    delete: `${baseUrlApi3}user/storage/{{type}}/{{target}}/{{id}}`,
    labels: `${baseUrlApi3}user/storage/labels`,
  },
}
