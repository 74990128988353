//core
import React, {useEffect, useCallback, useState, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebounce } from "usehooks-ts";
import format from "date-fns/format";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "../../../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";
import { useHeaderStyles } from "../../../../../UI/PageHeaderStyles/styles";

//helpers
import { getDataIds, getOptionsList, getPeriod, getStringIds } from "../../../utils";

//selectors
import {
  selectListData,
  selectOptionsData,
  selectDownloadData,
  selectColumnsData,
} from "../../../../../../store/affiliate/reports/summary_report_v2/selectors";

//hooks
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";
import { useUI } from "../../../../../../store/common/ui/useUI";
import { useSummaryReport } from "../../../../../../store/affiliate/reports/summary_report_v2/useSummaryReport";
import { usePermissions } from "../../../../../../store/common/configuration/usePermissions";

//context
import { GlobalContext } from "../../../../../../context/GlobalContext";

//components
import DrawerFilter from "../DrawerFilter";
import { ISummaryReportFilterDataType, OrderType } from "../../types";
import TableData from "../../../../../common/TableData";
import CategorySearch from "../../../components/CategorySearch";
import MuiModal from "../../../../../UI/MuiModal";
import DownloadReport from "../DownloadReport";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";

const initialSummaryReportOrderState: OrderType = {
  transaction_date_for_reports: 'desc',
}

export const initialSummaryReportFilterData: ISummaryReportFilterDataType = {
  period: 'month',
  sent_from: getPeriod('month').start,
  sent_to: getPeriod('month').end,
  group_by: {
    fields: [],
  },
  productIds: [],
  platformIds: [],
  trackerIds: [],
  creativeIds: [],
  brands: [],
  currencies: [],
  dealTypes: [],
  registrationFrom: '',
  registrationTo: '',
  firstDepositFrom: '',
  firstDepositTo: '',
  anid: [],
  playerId: [],
  source1: [],
  source2: [],
  source3: [],
  source4: [],
  source5: [],
};


const DesktopReport = () => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const { hasPermissions } = usePermissions();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const { setGeneralSnackbar } = useUI();
  const {
    getList,
    getColumns,
    setDownload,
    clearDownloadState,
    resetState,
  } = useSummaryReport();

  const data = useSelector(selectListData);
  const filterData = useSelector(selectOptionsData);
  const downloadData = useSelector(selectDownloadData);
  const columnsData = useSelector(selectColumnsData);

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showMobileTooltip, setShowMobileTooltip] = useState<boolean>(false);
  const [mobileFilterIsOpen, setMobileFilterIsOpen] = useState<boolean>(true);
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [showSearchField, setShowSearchField] = useState(false);

  const debouncedValue = useDebounce<string>(searchValue, 500);

  const [filter, setFilter] = useState<ISummaryReportFilterDataType>(initialSummaryReportFilterData);
  const [selectedFilterId, setSelectedFilterId] = useState<string>('');
  const [order, setOrder] = useState<OrderType>(initialSummaryReportOrderState);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [columns, setColumns] = useState<TableColumnsItem[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<TableColumnsItem[]>([]);

  useEffect(() => {
    //get columns data
    if (hasPermissions(["api3.affiliate.playercommissionreportcontroller.sort_columns"])) {
      if (!columns.length) {
        getColumns();
      }
    }
  }, []);

  useEffect(() => {
    if (!!columnsData) {
      const newColumns = columnsData.map((item: string) => {
        return {
          id: item,
          value: item,
          checked: true,
        };
      });

      setColumns(newColumns);
      setDefaultColumns(newColumns);
    }
  }, [columnsData]);

  useEffect(() => {
    if (!!data && !!data.search_fields && !!data.search_fields.length) {
      if (!data.search_fields.includes(searchFilter)) {
        setSearchFilter(getOptionsList(data.search_fields)[0].id);
        setShowSearchField(true);
      }
    }
  }, [data]);

  useDidMountEffect(() => {
    if (!!filter) {
      const payload = getPayloadData(page+1);
      getList(payload);
    }
  }, [page]);

  useDidMountEffect(() => {
    setPage(0);
    if (!!filter) {
      const payload = getPayloadData(1);
      getList(payload);
    }
  }, [rowsPerPage, order]);

  useDidMountEffect(() => {
    if (!!filter) {
      if (searchValue.length >= 2 || searchValue === '') {
        setPage(0);
        const payload = getPayloadData(1);
        getList(payload);
      }
    }
  }, [debouncedValue]);

  useDidMountEffect(() => {
    handleGenerateReport();
  }, [generateReport]);

  useEffect(() => {
    return () => {
      resetState();
      context.resetAffSummaryReportFilter();
    };
  }, []);

  useEffect(() => {
    setSearchValue('');
    setSearchFilter('');
    setShowSearchField(false);
  }, [filter.group_by]);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  },[setSearchValue]);

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const handleChangeSearchFilter = useCallback((event: SelectChangeEvent) => {
    setSearchFilter(event.target.value);
  }, [setSearchFilter]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  },[setRowsPerPage, setPage]);

  const handleDownloadReport = useCallback(() => {
    setShowDownloadModal(true);
  }, [setShowDownloadModal]);

  const handleGenerateReport = (): void => {
    setPage(0);
    setOrder(initialSummaryReportOrderState);
    setSearchValue('')
    const payload = getPayloadData(1);
    getList(payload);

    setMobileFilterIsOpen(false);

    if (!showMobileTooltip) {
      setShowMobileTooltip(true);
      if (mobile) {
        setGeneralSnackbar({
          open: true,
          type: 'info',
          messageKey: 'common.messages.report_page_loaded'
        });
      }
    }
  }

  const getPayloadData = (page: number) => {
    const orderedColumns = columns.reduce((acc: string[], item) => {
      if (item.checked) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    return {
      page: page,
      per_page: rowsPerPage,
      order: { ...order },
      ...(hasPermissions(['api3.affiliate.playercommissionreportcontroller.sort_columns']) ? { orderedColumns: orderedColumns } : {}),
      report: {
        period: {
          start: format(new Date(filter.sent_from), 'yyyy-MM-dd'),
          end: format(new Date(filter.sent_to), 'yyyy-MM-dd'),
        },
        group_by: {
          fields: getStringIds(filter.group_by.fields),
        },
        //filter fields
        productIds: !!filter.productIds ? getDataIds(filter.productIds) : [],
        platformIds: !!filter.platformIds ? getDataIds(filter.platformIds) : [],
        trackerIds: !!filter.trackerIds ? getDataIds(filter.trackerIds) : [],
        creativeIds: !!filter.creativeIds ? getDataIds(filter.creativeIds) : [],
        brands: !!filter.brands ? getDataIds(filter.brands) : [],
        currencies: !!filter.currencies ? getDataIds(filter.currencies) : [],
        dealTypes: !!filter.dealTypes ? getDataIds(filter.dealTypes) : [],
        ...(!!filter.registrationFrom || !!filter.registrationTo ? {
          registrationPeriod: {
            from: !!filter.registrationFrom ? format(new Date(filter.registrationFrom), 'yyyy-MM-dd') : null,
            to: !!filter.registrationTo ? format(new Date(filter.registrationTo), 'yyyy-MM-dd') : null,
          },
        } : {}),
        ...(!!filter.firstDepositFrom || !!filter.firstDepositTo ? {
          firstDepositPeriod: {
            from: !!filter.firstDepositFrom ? format(new Date(filter.firstDepositFrom), 'yyyy-MM-dd') : null,
            to: !!filter.firstDepositTo ? format(new Date(filter.firstDepositTo), 'yyyy-MM-dd') : null,
          },
        } : {}),
        encoding: "Unicode, 8-bit",
        format_file: "CSV",
        ...( searchValue.length >= 2 ? {
          searchField: {
            key: searchFilter,
            value: searchValue,
          }
        } : {}),
        ...(!!filter.anid.length ? {
          anid: filter.anid,
        } : {}),
        ...(!!filter.playerId.length ? {
          playerId: filter.playerId,
        } : {}),
        ...(!!filter.source1.length ? {
          source1: filter.source1,
        } : {}),
        ...(!!filter.source2.length ? {
          source2: filter.source2,
        } : {}),
        ...(!!filter.source3.length ? {
          source3: filter.source3,
        } : {}),
        ...(!!filter.source4.length ? {
          source4: filter.source4,
        } : {}),
        ...(!!filter.source5.length ? {
          source5: filter.source5,
        } : {}),
      },
    }
  }

  return (
    <Box>
      {mobile && (<>
        <Box className={headerStyles.header}>
          <Box className={headerStyles.headerBottom}>
            <Box className={headerStyles.headerBottomWrapper}>
              <Typography className={headerStyles.pageTitle} variant="h6">{t("admin.reports.commission_report.title")}</Typography>
              <Button
                gradient
                sx={{ width: 'max-content' }}
                disableElevation
                type="submit"
                variant="contained"
                size="small"
                onClick={handleGenerateReport}
              >
                { t("common.buttons.generate") }
              </Button>
            </Box>
          </Box>
        </Box>
      </>)}

      <Box className={styles.filters}>
        <DrawerFilter
          initialFilter={filter}
          setUpdateFilter={setFilter}
          order={order}
          setOrder={setOrder}
          onDownload={handleDownloadReport}
          onGenerateReport={handleGenerateReport}
          setGenerateReport={setGenerateReport}
          columns={columns}
          setColumns={setColumns}
          defaultColumns={defaultColumns}
          selectedFilterId={selectedFilterId}
          setSelectedFilterId={setSelectedFilterId}
        >
          {!mobile && (
            <Typography variant="h6">{t("admin.reports.commission_report.title")}</Typography>
          )}
        </DrawerFilter>
      </Box>

      <Box className={headerStyles.contentWrapper}>
        {!!data && (
          <>
            {showSearchField && (
              <Box className={styles.tableHeader}>
                <CategorySearch
                  hideSearch={!getOptionsList(data.search_fields).length}
                  selected={searchFilter}
                  options={getOptionsList(data.search_fields)}
                  prefix="common.components.report_search_options."
                  value={searchValue}
                  name="search"
                  onChange={handleChangeSearch}
                  onClear={handleClearSearch}
                  onChangeSelect={handleChangeSearchFilter}
                />
              </Box>
            )}
            <Box
              sx={{ paddingTop: showSearchField ? !getOptionsList(data.search_fields).length ? 0 : '16px' : 0, }}
            >
              <TableData
                list={data.list}
                columns={data.columns}
                totalRow={data.sum_columns}
                order={order}
                setOrder={setOrder}
                page={page}
                rowsPerPage={rowsPerPage}
                totalEntries={+data.total_entries}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actionsCount={1}
                translationColumnsPrefix="columns_for_display.table.reports.columns."
                translationTooltipsPrefix="columns_for_display.table.reports.tooltips."
                stickyHeader={true}
                detailModalTitle="date_for_report"
                showDetailOnMobile={true}
                startRowNum={data.start_row_num}
                endRowNum={data.end_row_num}
              />
            </Box>
          </>
        )}
      </Box>

      <MuiModal
        open={showDownloadModal}
        closeOnOutside={false}
        setToggleModal={setShowDownloadModal}
      >
        {!!filterData && !!filter && (
          <DownloadReport
            order={order}
            columns={columns}
            downloadOnThisPage={false}
            downloadData={downloadData}
            encodingTypes={filterData.encoding_types}
            separatorsForFormat={filterData.separators_for_format}
            filter={filter}
            setToggleModal={setShowDownloadModal}
            setDownload={setDownload}
            clearDownloadState={clearDownloadState}
          />
        )}
      </MuiModal>
    </Box>
  );
};

export default DesktopReport;
