//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";

//service
import { detailsFinanceReportService } from "../../../../services/admin/details_finance_report.service";
import {
  setAddAdjustmentState,
  setApproveAdjustmentState,
  setEditAdjustmentState,
  setGeneralProgressShow,
  setGeneralProgressHide,
  setGeneralSnackbarState,
  setCreateCreativeState
} from "../../../common/ui";

//helpers
import { downloadBlobCsv } from "../../../../helpers/downloadBlobCsv";
import { setUser } from "../../../common/user";

type filterDataItemList = {
  id: number;
  value: string;
}

type filtersDataType = {
  list: filterDataItemList[];
  total_entries: number;
  start_row_num: number;
  end_row_num: number;
  total_pages: number;
  label?: string;
  search?: string
  updateList?: boolean
}

export type PaymentSetData = {
  periodId: number;
  affiliateId: number;
  brand: string;
  currency: string;
  amount: string;
};

export type detailsFinanceReportSliceState = {
  list: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  columns: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: {
      departments: filtersDataType | null;
      chiefs: filtersDataType | null;
      affiliates: filtersDataType | null;
      currencies: filtersDataType | null;
      statuses: filtersDataType | null;
    },
    error: any,
    isFetching: boolean,
  },
  adjustmentList: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentReasons: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentBrands: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentAdd: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentEdit: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  adjustmentRemove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  detailsFinanceReportData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  downloadDetailsReport: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  downloadDetailsReportLogs: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  paymentChange: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  managerApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  cancelManagerApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  toPaidApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  processingPayment: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  cancelProcessingPayment: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  carryOverApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  cancelCarryOverApprove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  changeComment: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  brandsList: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  reportRowData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  paymentBreakdown: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  affiliatesIds: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  addPayoutRow: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  payoutsList: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  createPayout: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  updatePayout: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  removePayout: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  availablePayments: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  editPayment: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  logs: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  get_invoice: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  upload_invoice: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  remove_invoice: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  periodsList: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  convertCurrency: {
    data: any,
    error: any,
    isFetching: boolean,
  },
};

export interface ISelectedRow {
  affiliateId: number;
  brand: string;
  currency: string;
}

export interface IApproveReports {
  periodId: string;
  reports: ISelectedRow[];
}

const initialState: detailsFinanceReportSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  columns: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: {
      departments: null,
      chiefs: null,
      affiliates: null,
      currencies: null,
      statuses: null,
    },
    error: null,
    isFetching: false,
  },
  adjustmentList: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentReasons: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentBrands: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentAdd: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentEdit: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  adjustmentRemove: {
    data: null,
    error: null,
    isFetching: false,
  },
  detailsFinanceReportData: {
    data: null,
    error: null,
    isFetching: false,
  },
  downloadDetailsReport: {
    data: null,
    error: null,
    isFetching: false,
  },
  downloadDetailsReportLogs: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentChange: {
    data: null,
    error: null,
    isFetching: false,
  },
  managerApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  cancelManagerApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  toPaidApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  processingPayment: {
    data: null,
    error: null,
    isFetching: false,
  },
  cancelProcessingPayment: {
    data: null,
    error: null,
    isFetching: false,
  },
  carryOverApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  cancelCarryOverApprove: {
    data: null,
    error: null,
    isFetching: false,
  },
  changeComment: {
    data: null,
    error: null,
    isFetching: false,
  },
  brandsList: {
    data: null,
    error: null,
    isFetching: false,
  },
  reportRowData: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentBreakdown: {
    data: null,
    error: null,
    isFetching: false,
  },
  affiliatesIds: {
    data: null,
    error: null,
    isFetching: false,
  },
  addPayoutRow: {
    data: null,
    error: null,
    isFetching: false,
  },
  payoutsList: {
    data: null,
    error: null,
    isFetching: false,
  },
  createPayout: {
    data: null,
    error: null,
    isFetching: false,
  },
  updatePayout: {
    data: null,
    error: null,
    isFetching: false,
  },
  removePayout: {
    data: null,
    error: null,
    isFetching: false,
  },
  availablePayments: {
    data: null,
    error: null,
    isFetching: false,
  },
  editPayment: {
    data: null,
    error: null,
    isFetching: false,
  },
  logs: {
    data: null,
    error: null,
    isFetching: false,
  },
  get_invoice: {
    data: null,
    error: null,
    isFetching: false,
  },
  upload_invoice: {
    data: null,
    error: null,
    isFetching: false,
  },
  remove_invoice: {
    data: null,
    error: null,
    isFetching: false,
  },
  periodsList: {
    data: null,
    error: null,
    isFetching: false,
  },
  convertCurrency: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getListData: any = createAsyncThunk(
  'details_finance_report/list',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getListData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return {...data, ...(!!payload && payload.hasOwnProperty('convert_currency') ? {rate_currency: payload.convert_currency} : {})};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getColumnsData: any = createAsyncThunk(
  'details_finance_report/getColumnsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getColumns();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getFiltersData: any = createAsyncThunk(
    'details_finance_report/getFiltersData',
    async (payload:any, { rejectWithValue }) => {
      try {
        const response = await detailsFinanceReportService.getFiltersData(payload);
        const data = await response.json();
        if (!response.ok) {
          return rejectWithValue(data)
        }

        return {
          ...data,
          label: payload.label,
          search: payload.search,
          updateList: payload.updateList,
        };
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);

export const getFiltersField: any = createAsyncThunk(
  'details_finance_report/getFiltersData',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await detailsFinanceReportService.getFiltersData(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAffiliatesIdsField: any = createAsyncThunk(
  'details_finance_report/getAffiliatesIdsField',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await detailsFinanceReportService.getFiltersData(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getBrandsListData: any = createAsyncThunk(
  'details_finance_report/getBrandsListData',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await detailsFinanceReportService.getBrandsList(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAdjustmentListData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/list',
    async (payload:any, { rejectWithValue }) => {
      try {
        const response = await detailsFinanceReportService.getAdjustmentList(payload);
        const data = await response.json();
        if (!response.ok) {
          return rejectWithValue(data)
        }

        return {...data, ...(!!payload && payload.hasOwnProperty('convert_currency') ? {rate_currency: payload.convert_currency} : {})};
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);

export const getAdjustmentReasonsData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/reasons',
    async (payload:any, { rejectWithValue }) => {
      try {
        const response = await detailsFinanceReportService.getAdjustmentReasons(payload);
        const data = await response.json();
        if (!response.ok) {
          return rejectWithValue(data)
        }

        return data;
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);

export const getAdjustmentBrandsData: any = createAsyncThunk(
  'finance_report/detailed/adjustment/brands',
  async (_: any, { rejectWithValue }) => {
    try {
      const response = await detailsFinanceReportService.getAdjustmentBrands();
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const setAdjustmentAddData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/add',
    async (payload:any, { rejectWithValue, dispatch }) => {
      dispatch(setGeneralProgressShow());
      try {
        const response = await detailsFinanceReportService.setAdjustmentAdd(payload);
        const data = await response.json();

        if (response.ok && response.status === 201) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'success',
                message: 'success',
                messageKey: 'common.messages.adjustment_entered',
              })
          );
          dispatch(setAddAdjustmentState(false));
        }

        if (!response.ok) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: 'error',
              messageKey: 'common.messages.error',
            })
          );
          dispatch(setGeneralProgressHide());
          return rejectWithValue(data)
        }

        dispatch(setGeneralProgressHide());

        return data;
      } catch (error) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(error)
      }
    }
);

export const setAdjustmentEditData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/edit',
    async (payload:any, { rejectWithValue, dispatch }) => {
      dispatch(setGeneralProgressShow());
      try {
        const response = await detailsFinanceReportService.setAdjustmentEdit(payload);
        const data = await response.json();

        if (response.ok && response.status === 200) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'success',
              message: 'success',
              messageKey: 'common.messages.success',
            })
          );
          dispatch(setEditAdjustmentState(false));
        }

        if (!response.ok) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'warning',
                message: 'error',
                messageKey: 'common.messages.error',
              })
          );
          dispatch(setGeneralProgressHide());
          return rejectWithValue(data);
        }

        dispatch(setGeneralProgressHide());

        return data;
      } catch (error) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(error)
      }
    }
);

export const setAdjustmentApproveData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/approve',
    async (payload:any, { rejectWithValue, dispatch }) => {
      dispatch(setGeneralProgressShow());
      try {
        const response = await detailsFinanceReportService.setAdjustmentApprove(payload);
        const data = await response.json();

        if (response.ok && response.status === 200) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'success',
                message: 'success',
                messageKey: 'common.messages.adjustment_approved',
              })
          );
          dispatch(setApproveAdjustmentState(false));
        }

        if (!response.ok) {
          return rejectWithValue(data)
        }

        dispatch(setGeneralProgressHide());

        return {id: payload.id};
      } catch (error) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(error)
      }
    }
);

export const setAdjustmentApprovePaidData: any = createAsyncThunk(
  'finance_report/detailed/adjustment/approve_paid',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setAdjustmentApprovePaid(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.adjustment_approved',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return {id: payload.id};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setAdjustmentApproveCoverPrepaymentPaidData: any = createAsyncThunk(
  'finance_report/detailed/adjustment/approve_cover_prepayment_paid',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setAdjustmentApproveCoverPrepaymentPaid(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.adjustment_approved',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return {id: payload.id};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setAdjustmentRemoveData: any = createAsyncThunk(
    'finance_report/detailed/adjustment/deleted',
    async (payload:any, { rejectWithValue, dispatch }) => {
      dispatch(setGeneralProgressShow());
      try {
        const response = await detailsFinanceReportService.setAdjustmentRemove(payload);
        const data = await response.json();

        if (response.ok && response.status === 200) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'success',
                message: 'success',
                messageKey: 'common.messages.removed',
              })
          );
        }

        if (!response.ok) {
          return rejectWithValue(data)
        }

        dispatch(setGeneralProgressHide());

        return {id: payload.id};
      } catch (error) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(error)
      }
    }
);

export const getDownloadDetailsReport: any = createAsyncThunk(
  'finance_report/detailed/download',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.downloadReport(payload);
      const fileName = await response.headers.get('x-file-name');
      const data = await response.text();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );

        downloadBlobCsv(data, fileName || 'payout_report.csv');
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getDownloadDetailsReportWithLogs: any = createAsyncThunk(
  'finance_report/detailed/download_logs',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.downloadReportLogs(payload);
      const fileName = await response.headers.get('x-file-name');
      const data = await response.text();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );

        downloadBlobCsv(data, fileName || 'payout_report.csv');
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setPaymentData: any = createAsyncThunk(
  'finance_report/detailed/payment/change',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setPayment(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setManagerApproveData: any = createAsyncThunk(
  'finance_report/detailed/setManagerApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setManagerApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setFinManagerApproveData: any = createAsyncThunk(
  'finance_report/detailed/setFinManagerApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setFinManagerApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setCancelManagerApproveData: any = createAsyncThunk(
  'finance_report/detailed/setCancelManagerApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setCancelManagerApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setToPaidApproveData: any = createAsyncThunk(
  'finance_report/detailed/setToPaidApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setToPaidApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setProcessingPaymentData: any = createAsyncThunk(
  'finance_report/detailed/setProcessingPaymentData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setProcessingPaymentApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setCancelProcessingPaymentData: any = createAsyncThunk(
  'finance_report/detailed/setCancelProcessingPaymentData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setCancelProcessingPaymentApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setCarryOverApproveData: any = createAsyncThunk(
  'finance_report/detailed/setCarryOverApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setCarryOverApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setCancelCarryOverApproveData: any = createAsyncThunk(
  'finance_report/detailed/setCancelCarryOverApproveData',
  async (payload: IApproveReports, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setCancelCarryOverApprove(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//changeComment
export const setChangeCommentData: any = createAsyncThunk(
  'finance_report/detailed/setChangeCommentData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setChangeComment(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        if (data.hasOwnProperty('errors') && data.errors.hasOwnProperty('error')) {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: data.errors.error,
              messageKey: '',
            })
          );
        }

        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return {
        ...data,
        affiliateId: payload.affiliateId,
        brand: payload.brand,
        currency: payload.currency,
      };
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getReportRowData: any = createAsyncThunk(
  'details_finance_report/getReportRowData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getReportRowData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentBreakdownData: any = createAsyncThunk(
  'details_finance_report/getPaymentBreakdownData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getPaymentBreakdownData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return {...data, ...(!!payload && payload.hasOwnProperty('convert_currency') ? {rate_currency: payload.convert_currency} : {})};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setPayoutRowData: any = createAsyncThunk(
  'details_finance_report/setPayoutRowData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.setPayoutRow(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPayoutsData: any = createAsyncThunk(
  'details_finance_report/getPayoutsData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getPayouts(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return {...data, ...(!!payload && payload.hasOwnProperty('convert_currency') ? {rate_currency: payload.convert_currency} : {})};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createPayoutData: any = createAsyncThunk(
  'details_finance_report/createPayoutData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.createPayout(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updatePayoutData: any = createAsyncThunk(
  'details_finance_report/updatePayoutData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.updatePayout(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const removePayoutData: any = createAsyncThunk(
  'details_finance_report/removePayoutData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.removePayout(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getAvailablePaymentsData: any = createAsyncThunk(
  'details_finance_report/getAvailablePaymentsData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getAvailablePayments(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const editPaymentData: any = createAsyncThunk(
  'details_finance_report/editPaymentData',
  async (payload: PaymentSetData, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.editPayment(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getLogsData: any = createAsyncThunk(
  'details_finance_report/getLogsData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getLogs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getInvoiceData: any = createAsyncThunk(
  'details_finance_report/getInvoiceData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getInvoice(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const uploadInvoiceData: any = createAsyncThunk(
  'details_finance_report/uploadInvoiceData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.uploadInvoice(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const removeInvoiceData: any = createAsyncThunk(
  'details_finance_report/removeInvoiceData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.removeInvoice(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPeriodsListData: any = createAsyncThunk(
  'details_finance_report/getPeriodsListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getPeriodsList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getConvertCurrencyData: any = createAsyncThunk(
  'details_finance_report/getConvertCurrencyData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await detailsFinanceReportService.getConvertCurrency(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const detailsFinanceReportSlice = createSlice({
  name: 'details_finance_report',
  initialState: initialState,
  reducers: {
    financeReportSetError(state, action) {
      state.detailsFinanceReportData.error = action.payload;
    },
    setFinanceReportData(state, action) {
      state.detailsFinanceReportData.data = action.payload;
    },
    clearFiltersData(state) {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = {
        departments: null,
        chiefs: null,
        affiliates: null,
        currencies: null,
        statuses: null,
      };
    },
    clearPaymentData(state) {
      state.paymentChange.data = null;
      state.paymentChange.error = null;
      state.paymentChange.isFetching = false;
    },
    clearManagerApproveData(state) {
      state.managerApprove.data = null;
      state.managerApprove.error = null;
      state.managerApprove.isFetching = false;
    },
    clearCancelManagerApproveData(state) {
      state.cancelManagerApprove.data = null;
      state.cancelManagerApprove.error = null;
      state.cancelManagerApprove.isFetching = false;
    },
    clearToPaidApproveData(state) {
      state.toPaidApprove.data = null;
      state.toPaidApprove.error = null;
      state.toPaidApprove.isFetching = false;
    },
    clearProcessingPaymentData(state) {
      state.processingPayment.data = null;
      state.processingPayment.error = null;
      state.processingPayment.isFetching = false;
    },
    clearCancelProcessingPaymentData(state) {
      state.cancelProcessingPayment.data = null;
      state.cancelProcessingPayment.error = null;
      state.cancelProcessingPayment.isFetching = false;
    },
    clearCarryOverApproveData(state) {
      state.carryOverApprove.data = null;
      state.carryOverApprove.error = null;
      state.carryOverApprove.isFetching = false;
    },
    clearCancelCarryOverApproveData(state) {
      state.cancelCarryOverApprove.data = null;
      state.cancelCarryOverApprove.error = null;
      state.cancelCarryOverApprove.isFetching = false;
    },
    clearAdjustmentEditData(state) {
      state.adjustmentEdit.data = null;
      state.adjustmentEdit.error = null;
      state.adjustmentEdit.isFetching = false;
    },
    clearAdjustmentAddData(state) {
      state.adjustmentAdd.data = null;
      state.adjustmentAdd.error = null;
      state.adjustmentAdd.isFetching = false;
    },
    clearAdjustmentListData(state) {
      state.adjustmentList.data = null;
      state.adjustmentList.error = null;
      state.adjustmentList.isFetching = false;
    },
    clearAdjustmentApproveData(state) {
      state.adjustmentApprove.data = null;
      state.adjustmentApprove.error = null;
      state.adjustmentApprove.isFetching = false;
    },
    clearDetailsListData(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearColumnsState(state) {
      state.columns.data = null;
      state.columns.error = null;
      state.columns.isFetching = false;
    },
    clearChangeCommentData(state) {
      state.changeComment.data = null;
      state.changeComment.error = null;
      state.changeComment.isFetching = false;
    },
    clearReportRowData(state) {
      state.reportRowData.data = null;
      state.reportRowData.error = null;
      state.reportRowData.isFetching = false;
    },
    clearPaymentBreakdownData(state) {
      state.paymentBreakdown.data = null;
      state.paymentBreakdown.error = null;
      state.paymentBreakdown.isFetching = false;
    },
    clearAddPayoutRowData(state) {
      state.addPayoutRow.data = null;
      state.addPayoutRow.error = null;
      state.addPayoutRow.isFetching = false;
    },
    clearPayoutsListData(state) {
      state.payoutsList.data = null;
      state.payoutsList.error = null;
      state.payoutsList.isFetching = false;
    },
    clearEditPaymentData(state) {
      state.editPayment.data = null;
      state.editPayment.error = null;
      state.editPayment.isFetching = false;
    },
    clearLogsListData(state) {
      state.logs.data = null;
      state.logs.error = null;
      state.logs.isFetching = false;
    },
    clearАvailablePaymentsData(state) {
      state.availablePayments.data = null;
      state.availablePayments.error = null;
      state.availablePayments.isFetching = false;
    },
    clearCreatePayoutData(state) {
      state.createPayout.data = null;
      state.createPayout.error = null;
      state.createPayout.isFetching = false;
    },
    clearUpdatePayoutData(state) {
      state.updatePayout.data = null;
      state.updatePayout.error = null;
      state.updatePayout.isFetching = false;
    },
    clearRemovePayoutData(state) {
      state.removePayout.data = null;
      state.removePayout.error = null;
      state.removePayout.isFetching = false;
    },
    clearGetInvoiceData(state) {
      state.get_invoice.data = null;
      state.get_invoice.error = null;
      state.get_invoice.isFetching = false;
    },
    clearUploadInvoiceData(state) {
      state.upload_invoice.data = null;
      state.upload_invoice.error = null;
      state.upload_invoice.isFetching = false;
    },
    clearAdjustmentsRemoveData(state) {
      state.adjustmentRemove.data = null;
      state.adjustmentRemove.error = null;
      state.adjustmentRemove.isFetching = false;
    },
    clearPeriodsListData(state) {
      state.periodsList.data = null;
      state.periodsList.error = null;
      state.periodsList.isFetching = false;
    },
    clearAffiliatesIdsData(state) {
      state.affiliatesIds.data = null;
      state.affiliatesIds.error = null;
      state.affiliatesIds.isFetching = false;
    },
    clearСonvertCurrencyData(state) {
      state.convertCurrency.data = null;
      state.convertCurrency.error = null;
      state.convertCurrency.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<detailsFinanceReportSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state, action ) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state, action ) => {
      state.list.error = action.payload;
    });
    builder.addCase(getColumnsData.pending, (state ) => {
      state.columns.isFetching = true;
      state.columns.error = null;
    });
    builder.addCase(getColumnsData.fulfilled, (state , action) => {
      state.columns.error = null;
      state.columns.isFetching = false;
      state.columns.data = action.payload;
    });
    builder.addCase(getColumnsData.rejected, (state , action) => {
      state.columns.isFetching = false;
      state.columns.error = action.payload;
    });
    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state, action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      //@ts-ignore
      if (state.filters.data[action.payload.label] && state.filters.data[action.payload.label].search === action.payload.search && action.payload.updateList !== true) {
        //@ts-ignore
        state.filters.data[action.payload.label] = {
          ...action.payload,
          list: [
            //@ts-ignore
            ...(state.filters.data[action.payload.label] ? state.filters.data[action.payload.label].list : []),
            ...action.payload.list
          ],
        }
      } else {
        //@ts-ignore
        state.filters.data[action.payload.label] = {
          ...action.payload,
          list: [
            //@ts-ignore
              ...action.payload.list
          ],
        }
      }
        //@ts-ignore
      if (!!state.filters.data[action.payload.label] && state.filters.data[action.payload.label].label !== 'statuses') {
        //@ts-ignore
        state.filters.data[action.payload.label].list.sort((prev,next)=> {
          if ( prev.id < next.id ) return -1;
          if ( prev.id < next.id ) return 1;
        });
      }
    });
    builder.addCase(getFiltersData.rejected, (state, action ) => {
      state.filters.error = action.payload;
    });
    builder.addCase(getAdjustmentListData.pending, (state ) => {
      state.adjustmentList.isFetching = true;
      state.adjustmentList.error = null;
    });
    builder.addCase(getAdjustmentListData.fulfilled, (state, action ) => {
      state.adjustmentList.error = null;
      state.adjustmentList.isFetching = false;
      state.adjustmentList.data = action.payload;
    });
    builder.addCase(getAdjustmentListData.rejected, (state, action ) => {
      state.adjustmentList.error = action.payload;
    });
    builder.addCase(getAdjustmentReasonsData.pending, (state ) => {
      state.adjustmentReasons.isFetching = true;
      state.adjustmentReasons.error = null;
    });
    builder.addCase(getAdjustmentReasonsData.fulfilled, (state, action ) => {
      state.adjustmentReasons.error = null;
      state.adjustmentReasons.isFetching = false;
      state.adjustmentReasons.data = action.payload;
    });
    builder.addCase(getAdjustmentReasonsData.rejected, (state, action ) => {
      state.adjustmentReasons.error = action.payload;
    });
    builder.addCase(getAdjustmentBrandsData.pending, (state ) => {
      state.adjustmentBrands.isFetching = true;
      state.adjustmentBrands.error = null;
    });
    builder.addCase(getAdjustmentBrandsData.fulfilled, (state, action ) => {
      state.adjustmentBrands.error = null;
      state.adjustmentBrands.isFetching = false;
      state.adjustmentBrands.data = action.payload;
    });
    builder.addCase(getAdjustmentBrandsData.rejected, (state, action ) => {
      state.adjustmentBrands.error = action.payload;
    });
    builder.addCase(setAdjustmentAddData.pending, (state ) => {
      state.adjustmentAdd.isFetching = true;
      state.adjustmentAdd.error = null;
    });
    builder.addCase(setAdjustmentAddData.fulfilled, (state, action ) => {
      state.adjustmentAdd.error = null;
      state.adjustmentAdd.isFetching = false;
      state.adjustmentAdd.data = action.payload;
    });
    builder.addCase(setAdjustmentAddData.rejected, (state, action ) => {
      state.adjustmentAdd.error = action.payload;
    });
    builder.addCase(setAdjustmentEditData.pending, (state ) => {
      state.adjustmentEdit.isFetching = true;
      state.adjustmentEdit.error = null;
    });
    builder.addCase(setAdjustmentEditData.fulfilled, (state, action ) => {
      state.adjustmentEdit.error = null;
      state.adjustmentEdit.isFetching = false;
      state.adjustmentEdit.data = action.payload;
    });
    builder.addCase(setAdjustmentEditData.rejected, (state, action ) => {
      state.adjustmentEdit.error = action.payload;
    });
    builder.addCase(setAdjustmentApproveData.pending, (state ) => {
      state.adjustmentApprove.isFetching = true;
      state.adjustmentApprove.error = null;
    });
    builder.addCase(setAdjustmentApproveData.fulfilled, (state, action ) => {
      state.adjustmentApprove.error = null;
      state.adjustmentApprove.isFetching = false;
      state.adjustmentApprove.data = action.payload;
    });
    builder.addCase(setAdjustmentApproveData.rejected, (state, action ) => {
      state.adjustmentApprove.error = action.payload;
    });

    builder.addCase(setAdjustmentApprovePaidData.pending, (state ) => {
      state.adjustmentApprove.isFetching = true;
      state.adjustmentApprove.error = null;
    });
    builder.addCase(setAdjustmentApprovePaidData.fulfilled, (state, action ) => {
      state.adjustmentApprove.error = null;
      state.adjustmentApprove.isFetching = false;
      state.adjustmentApprove.data = action.payload;
    });
    builder.addCase(setAdjustmentApprovePaidData.rejected, (state, action ) => {
      state.adjustmentApprove.error = action.payload;
    });

    builder.addCase(setAdjustmentApproveCoverPrepaymentPaidData.pending, (state ) => {
      state.adjustmentApprove.isFetching = true;
      state.adjustmentApprove.error = null;
    });
    builder.addCase(setAdjustmentApproveCoverPrepaymentPaidData.fulfilled, (state, action ) => {
      state.adjustmentApprove.error = null;
      state.adjustmentApprove.isFetching = false;
      state.adjustmentApprove.data = action.payload;
    });
    builder.addCase(setAdjustmentApproveCoverPrepaymentPaidData.rejected, (state, action ) => {
      state.adjustmentApprove.error = action.payload;
    });

    builder.addCase(setAdjustmentRemoveData.pending, (state ) => {
      state.adjustmentRemove.isFetching = true;
      state.adjustmentRemove.error = null;
    });
    builder.addCase(setAdjustmentRemoveData.fulfilled, (state, action ) => {
      state.adjustmentRemove.error = null;
      state.adjustmentRemove.isFetching = false;
      state.adjustmentRemove.data = action.payload;
    });
    builder.addCase(setAdjustmentRemoveData.rejected, (state, action ) => {
      state.adjustmentRemove.error = action.payload;
    });

    builder.addCase(getDownloadDetailsReport.pending, (state ) => {
      state.downloadDetailsReport.isFetching = true;
      state.downloadDetailsReport.error = null;
    });
    builder.addCase(getDownloadDetailsReport.fulfilled, (state, action ) => {
      state.downloadDetailsReport.error = null;
      state.downloadDetailsReport.isFetching = false;
      state.downloadDetailsReport.data = action.payload;
    });
    builder.addCase(getDownloadDetailsReport.rejected, (state, action ) => {
      state.downloadDetailsReport.error = action.payload;
    });

    builder.addCase(getDownloadDetailsReportWithLogs.pending, (state ) => {
      state.downloadDetailsReportLogs.isFetching = true;
      state.downloadDetailsReportLogs.error = null;
    });
    builder.addCase(getDownloadDetailsReportWithLogs.fulfilled, (state, action ) => {
      state.downloadDetailsReportLogs.error = null;
      state.downloadDetailsReportLogs.isFetching = false;
      state.downloadDetailsReportLogs.data = action.payload;
    });
    builder.addCase(getDownloadDetailsReportWithLogs.rejected, (state, action ) => {
      state.downloadDetailsReportLogs.error = action.payload;
    });

    builder.addCase(setPaymentData.pending, (state ) => {
      state.paymentChange.isFetching = true;
      state.paymentChange.error = null;
    });
    builder.addCase(setPaymentData.fulfilled, (state, action ) => {
      state.paymentChange.error = null;
      state.paymentChange.isFetching = false;
      state.paymentChange.data = action.payload;
    });
    builder.addCase(setPaymentData.rejected, (state, action ) => {
      state.paymentChange.error = action.payload;
    });

    builder.addCase(setManagerApproveData.pending, (state ) => {
      state.managerApprove.isFetching = true;
      state.managerApprove.error = null;
    });
    builder.addCase(setManagerApproveData.fulfilled, (state, action ) => {
      state.managerApprove.error = null;
      state.managerApprove.isFetching = false;
      state.managerApprove.data = action.payload;
    });
    builder.addCase(setManagerApproveData.rejected, (state, action ) => {
      state.managerApprove.data = null;
      state.managerApprove.isFetching = false;
      state.managerApprove.error = action.payload;
    });

    builder.addCase(setFinManagerApproveData.pending, (state ) => {
      state.managerApprove.isFetching = true;
      state.managerApprove.error = null;
    });
    builder.addCase(setFinManagerApproveData.fulfilled, (state, action ) => {
      state.managerApprove.error = null;
      state.managerApprove.isFetching = false;
      state.managerApprove.data = action.payload;
    });
    builder.addCase(setFinManagerApproveData.rejected, (state, action ) => {
      state.managerApprove.data = null;
      state.managerApprove.isFetching = false;
      state.managerApprove.error = action.payload;
    });

    builder.addCase(setCancelManagerApproveData.pending, (state ) => {
      state.cancelManagerApprove.isFetching = true;
      state.cancelManagerApprove.error = null;
    });
    builder.addCase(setCancelManagerApproveData.fulfilled, (state, action ) => {
      state.cancelManagerApprove.error = null;
      state.cancelManagerApprove.isFetching = false;
      state.cancelManagerApprove.data = action.payload;
    });
    builder.addCase(setCancelManagerApproveData.rejected, (state, action ) => {
      state.cancelManagerApprove.data = null;
      state.cancelManagerApprove.isFetching = false;
      state.cancelManagerApprove.error = action.payload;
    });

    builder.addCase(setToPaidApproveData.pending, (state ) => {
      state.toPaidApprove.isFetching = true;
      state.toPaidApprove.error = null;
    });
    builder.addCase(setToPaidApproveData.fulfilled, (state, action ) => {
      state.toPaidApprove.error = null;
      state.toPaidApprove.isFetching = false;
      state.toPaidApprove.data = action.payload;
    });
    builder.addCase(setToPaidApproveData.rejected, (state, action ) => {
      state.toPaidApprove.data = null;
      state.toPaidApprove.isFetching = false;
      state.toPaidApprove.error = action.payload;
    });

    builder.addCase(setProcessingPaymentData.pending, (state ) => {
      state.processingPayment.isFetching = true;
      state.processingPayment.error = null;
    });
    builder.addCase(setProcessingPaymentData.fulfilled, (state, action ) => {
      state.processingPayment.error = null;
      state.processingPayment.isFetching = false;
      state.processingPayment.data = action.payload;
    });
    builder.addCase(setProcessingPaymentData.rejected, (state, action ) => {
      state.processingPayment.data = null;
      state.processingPayment.isFetching = false;
      state.processingPayment.error = action.payload;
    });

    builder.addCase(setCancelProcessingPaymentData.pending, (state ) => {
      state.cancelProcessingPayment.isFetching = true;
      state.cancelProcessingPayment.error = null;
    });
    builder.addCase(setCancelProcessingPaymentData.fulfilled, (state, action ) => {
      state.cancelProcessingPayment.error = null;
      state.cancelProcessingPayment.isFetching = false;
      state.cancelProcessingPayment.data = action.payload;
    });
    builder.addCase(setCancelProcessingPaymentData.rejected, (state, action ) => {
      state.cancelProcessingPayment.data = null;
      state.cancelProcessingPayment.isFetching = false;
      state.cancelProcessingPayment.error = action.payload;
    });

    builder.addCase(setCarryOverApproveData.pending, (state ) => {
      state.carryOverApprove.isFetching = true;
      state.carryOverApprove.error = null;
    });
    builder.addCase(setCarryOverApproveData.fulfilled, (state, action ) => {
      state.carryOverApprove.error = null;
      state.carryOverApprove.isFetching = false;
      state.carryOverApprove.data = action.payload;
    });
    builder.addCase(setCarryOverApproveData.rejected, (state, action ) => {
      state.carryOverApprove.data = null;
      state.carryOverApprove.isFetching = false;
      state.carryOverApprove.error = action.payload;
    });

    builder.addCase(setCancelCarryOverApproveData.pending, (state ) => {
      state.cancelCarryOverApprove.isFetching = true;
      state.cancelCarryOverApprove.error = null;
    });
    builder.addCase(setCancelCarryOverApproveData.fulfilled, (state, action ) => {
      state.cancelCarryOverApprove.error = null;
      state.cancelCarryOverApprove.isFetching = false;
      state.cancelCarryOverApprove.data = action.payload;
    });
    builder.addCase(setCancelCarryOverApproveData.rejected, (state, action ) => {
      state.cancelCarryOverApprove.data = null;
      state.cancelCarryOverApprove.isFetching = false;
      state.cancelCarryOverApprove.error = action.payload;
    });

    builder.addCase(setChangeCommentData.pending, (state ) => {
      state.changeComment.isFetching = true;
      state.changeComment.error = null;
    });
    builder.addCase(setChangeCommentData.fulfilled, (state, action ) => {
      state.changeComment.error = null;
      state.changeComment.isFetching = false;
      state.changeComment.data = action.payload;
    });
    builder.addCase(setChangeCommentData.rejected, (state, action ) => {
      state.changeComment.data = null;
      state.changeComment.isFetching = false;
      state.changeComment.error = action.payload;
    });

    builder.addCase(getBrandsListData.pending, (state ) => {
      state.brandsList.isFetching = true;
      state.brandsList.error = null;
    });
    builder.addCase(getBrandsListData.fulfilled, (state, action ) => {
      state.brandsList.error = null;
      state.brandsList.isFetching = false;
      state.brandsList.data = action.payload;
    });
    builder.addCase(getBrandsListData.rejected, (state, action ) => {
      state.brandsList.data = null;
      state.brandsList.isFetching = false;
      state.brandsList.error = action.payload;
    });

    builder.addCase(getReportRowData.pending, (state ) => {
      state.reportRowData.isFetching = true;
      state.reportRowData.error = null;
    });
    builder.addCase(getReportRowData.fulfilled, (state, action ) => {
      state.reportRowData.error = null;
      state.reportRowData.isFetching = false;
      state.reportRowData.data = action.payload;
    });
    builder.addCase(getReportRowData.rejected, (state, action ) => {
      state.reportRowData.data = null;
      state.reportRowData.isFetching = false;
      state.reportRowData.error = action.payload;
    });

    builder.addCase(getPaymentBreakdownData.pending, (state ) => {
      state.paymentBreakdown.isFetching = true;
      state.paymentBreakdown.error = null;
    });
    builder.addCase(getPaymentBreakdownData.fulfilled, (state, action ) => {
      state.paymentBreakdown.error = null;
      state.paymentBreakdown.isFetching = false;
      state.paymentBreakdown.data = action.payload;
    });
    builder.addCase(getPaymentBreakdownData.rejected, (state, action ) => {
      state.paymentBreakdown.data = null;
      state.paymentBreakdown.isFetching = false;
      state.paymentBreakdown.error = action.payload;
    });

    builder.addCase(getAffiliatesIdsField.pending, (state ) => {
      state.affiliatesIds.isFetching = true;
      state.affiliatesIds.error = null;
    });
    builder.addCase(getAffiliatesIdsField.fulfilled, (state, action ) => {
      state.affiliatesIds.error = null;
      state.affiliatesIds.isFetching = false;
      state.affiliatesIds.data = action.payload;
    });
    builder.addCase(getAffiliatesIdsField.rejected, (state, action ) => {
      state.affiliatesIds.data = null;
      state.affiliatesIds.isFetching = false;
      state.affiliatesIds.error = action.payload;
    });

    builder.addCase(setPayoutRowData.pending, (state ) => {
      state.addPayoutRow.isFetching = true;
      state.addPayoutRow.error = null;
    });
    builder.addCase(setPayoutRowData.fulfilled, (state, action ) => {
      state.addPayoutRow.error = null;
      state.addPayoutRow.isFetching = false;
      state.addPayoutRow.data = action.payload;
    });
    builder.addCase(setPayoutRowData.rejected, (state, action ) => {
      state.addPayoutRow.data = null;
      state.addPayoutRow.isFetching = false;
      state.addPayoutRow.error = action.payload;
    });

    builder.addCase(getPayoutsData.pending, (state ) => {
      state.payoutsList.isFetching = true;
      state.payoutsList.error = null;
    });
    builder.addCase(getPayoutsData.fulfilled, (state, action ) => {
      state.payoutsList.error = null;
      state.payoutsList.isFetching = false;
      state.payoutsList.data = action.payload;
    });
    builder.addCase(getPayoutsData.rejected, (state, action ) => {
      state.payoutsList.data = null;
      state.payoutsList.isFetching = false;
      state.payoutsList.error = action.payload;
    });

    builder.addCase(createPayoutData.pending, (state ) => {
      state.createPayout.isFetching = true;
      state.createPayout.error = null;
    });
    builder.addCase(createPayoutData.fulfilled, (state, action ) => {
      state.createPayout.error = null;
      state.createPayout.isFetching = false;
      state.createPayout.data = action.payload;
    });
    builder.addCase(createPayoutData.rejected, (state, action ) => {
      state.createPayout.data = null;
      state.createPayout.isFetching = false;
      state.createPayout.error = action.payload;
    });

    builder.addCase(updatePayoutData.pending, (state ) => {
      state.updatePayout.isFetching = true;
      state.updatePayout.error = null;
    });
    builder.addCase(updatePayoutData.fulfilled, (state, action ) => {
      state.updatePayout.error = null;
      state.updatePayout.isFetching = false;
      state.updatePayout.data = action.payload;
    });
    builder.addCase(updatePayoutData.rejected, (state, action ) => {
      state.updatePayout.data = null;
      state.updatePayout.isFetching = false;
      state.updatePayout.error = action.payload;
    });

    builder.addCase(removePayoutData.pending, (state ) => {
      state.removePayout.isFetching = true;
      state.removePayout.error = null;
    });
    builder.addCase(removePayoutData.fulfilled, (state, action ) => {
      state.removePayout.error = null;
      state.removePayout.isFetching = false;
      state.removePayout.data = action.payload;
    });
    builder.addCase(removePayoutData.rejected, (state, action ) => {
      state.removePayout.data = null;
      state.removePayout.isFetching = false;
      state.removePayout.error = action.payload;
    });

    builder.addCase(getAvailablePaymentsData.pending, (state ) => {
      state.availablePayments.isFetching = true;
      state.availablePayments.error = null;
    });
    builder.addCase(getAvailablePaymentsData.fulfilled, (state, action ) => {
      state.availablePayments.error = null;
      state.availablePayments.isFetching = false;
      state.availablePayments.data = action.payload;
    });
    builder.addCase(getAvailablePaymentsData.rejected, (state, action ) => {
      state.availablePayments.data = null;
      state.availablePayments.isFetching = false;
      state.availablePayments.error = action.payload;
    });

    builder.addCase(editPaymentData.pending, (state ) => {
      state.editPayment.isFetching = true;
      state.editPayment.error = null;
    });
    builder.addCase(editPaymentData.fulfilled, (state, action ) => {
      state.editPayment.error = null;
      state.editPayment.isFetching = false;
      state.editPayment.data = action.payload;
    });
    builder.addCase(editPaymentData.rejected, (state, action ) => {
      state.editPayment.data = null;
      state.editPayment.isFetching = false;
      state.editPayment.error = action.payload;
    });

    builder.addCase(getLogsData.pending, (state ) => {
      state.logs.isFetching = true;
      state.logs.error = null;
    });
    builder.addCase(getLogsData.fulfilled, (state, action ) => {
      state.logs.error = null;
      state.logs.isFetching = false;
      state.logs.data = action.payload;
    });
    builder.addCase(getLogsData.rejected, (state, action ) => {
      state.logs.data = null;
      state.logs.isFetching = false;
      state.logs.error = action.payload;
    });

    builder.addCase(getInvoiceData.pending, (state ) => {
      state.get_invoice.isFetching = true;
      state.get_invoice.error = null;
    });
    builder.addCase(getInvoiceData.fulfilled, (state, action ) => {
      state.get_invoice.error = null;
      state.get_invoice.isFetching = false;
      state.get_invoice.data = action.payload;
    });
    builder.addCase(getInvoiceData.rejected, (state, action ) => {
      state.get_invoice.data = null;
      state.get_invoice.isFetching = false;
      state.get_invoice.error = action.payload;
    });

    builder.addCase(uploadInvoiceData.pending, (state ) => {
      state.upload_invoice.isFetching = true;
      state.upload_invoice.error = null;
    });
    builder.addCase(uploadInvoiceData.fulfilled, (state, action ) => {
      state.upload_invoice.error = null;
      state.upload_invoice.isFetching = false;
      state.upload_invoice.data = action.payload;
    });
    builder.addCase(uploadInvoiceData.rejected, (state, action ) => {
      state.upload_invoice.data = null;
      state.upload_invoice.isFetching = false;
      state.upload_invoice.error = action.payload;
    });

    builder.addCase(removeInvoiceData.pending, (state ) => {
      state.remove_invoice.isFetching = true;
      state.remove_invoice.error = null;
    });
    builder.addCase(removeInvoiceData.fulfilled, (state, action ) => {
      state.remove_invoice.error = null;
      state.remove_invoice.isFetching = false;
      state.remove_invoice.data = action.payload;
    });
    builder.addCase(removeInvoiceData.rejected, (state, action ) => {
      state.remove_invoice.data = null;
      state.remove_invoice.isFetching = false;
      state.upload_invoice.error = action.payload;
    });

    builder.addCase(getPeriodsListData.pending, (state ) => {
      state.periodsList.isFetching = true;
      state.periodsList.error = null;
    });
    builder.addCase(getPeriodsListData.fulfilled, (state, action ) => {
      state.periodsList.error = null;
      state.periodsList.isFetching = false;
      state.periodsList.data = action.payload;
    });
    builder.addCase(getPeriodsListData.rejected, (state, action ) => {
      state.periodsList.data = null;
      state.periodsList.isFetching = false;
      state.periodsList.error = action.payload;
    });

    builder.addCase(getConvertCurrencyData.pending, (state ) => {
      state.convertCurrency.isFetching = true;
      state.convertCurrency.error = null;
    });
    builder.addCase(getConvertCurrencyData.fulfilled, (state, action ) => {
      state.convertCurrency.error = null;
      state.convertCurrency.isFetching = false;
      state.convertCurrency.data = action.payload;
    });
    builder.addCase(getConvertCurrencyData.rejected, (state, action ) => {
      state.convertCurrency.data = null;
      state.convertCurrency.isFetching = false;
      state.convertCurrency.error = action.payload;
    });
  },
});

export default detailsFinanceReportSlice.reducer;

export const {
  financeReportSetError,
  setFinanceReportData,
  clearPaymentData,
  clearManagerApproveData,
  clearFiltersData,
  clearCancelManagerApproveData,
  clearToPaidApproveData,
  clearCarryOverApproveData,
  clearCancelCarryOverApproveData,
  clearAdjustmentEditData,
  clearAdjustmentAddData,
  clearAdjustmentListData,
  clearDetailsListData,
  clearChangeCommentData,
  clearReportRowData,
  clearColumnsState,
  clearPaymentBreakdownData,
  clearAddPayoutRowData,
  clearProcessingPaymentData,
  clearCancelProcessingPaymentData,
  clearPayoutsListData,
  clearEditPaymentData,
  clearLogsListData,
  clearАvailablePaymentsData,
  clearCreatePayoutData,
  clearUpdatePayoutData,
  clearRemovePayoutData,
  clearGetInvoiceData,
  clearUploadInvoiceData,
  clearAdjustmentsRemoveData,
  clearPeriodsListData,
  clearAffiliatesIdsData,
  clearСonvertCurrencyData,
  clearAdjustmentApproveData,
} = detailsFinanceReportSlice.actions;
